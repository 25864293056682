<template>
    <section class="I_Sec09_Par">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                        <div   class="B col s12 m8 ">
                            <div class="card padding">
                                <div class="row valign-wrapper">
                                    <div class="col s12">
                                    <p class="right tag">I</p>
                                    <h4>title</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam repellat ab fugiat alias qui obcaecati possimus, nulla inventore eum ullam.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col m6 s12">
                        <div class="input-field col s12" :value='I_Sec09_Par_Title' @input='$emit("update:I_Sec09_Par_Title", $event.target.value)' >
                            <input id="I_Sec09_Par_Title" type="text" class="validate">
                            <label for="I_Sec09_Par_Title"> Title Paragraph: </label>
                        </div>
                        <div class="input-field col s12" :value='I_Sec09_Par_Text' @input='$emit("update:I_Sec09_Par_Text", $event.target.value)'>
                            <textarea id="I_Sec09_Par_Text" class="materialize-textarea"></textarea>
                            <label for="I_Sec09_Par_Text">Text:</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
    I_Sec09_Par_Title: String,
    I_Sec09_Par_Text: String
    },
    data(){
        return {
        I_Sec09_Par_Title:"",
        I_Sec09_Par_Text: ""
        }
    }
}
</script>

<style>

</style>