<template>
    <div v-if="message" class="container center message">  
        <div class="">
            <h5>Je antwoorden werden succesvol verstuurd</h5>
            <h6>(je word automatisch teruggebracht naar lessen)</h6>
        </div>
    </div>
    <div v-else>
        <A_Sec01_TitleLesson /> 
        <objectives />
        <B_Sec02_Par />
        <C_Sec03_Quest01 v-model:answersQuest01="answersQuest01" ref="C_Sec03_Quest01Ref" />
        <D_Sec04_ParImgL />
        <E_Sec05_Quest02 v-model:answersQuest02="answersQuest02" ref="E_Sec05_Quest02Ref"/>
        <F_Sec06_ParImgR />
        <G_Sec07_Par />
        <H_Sec08_Quest03 v-model:answersQuest03="answersQuest03" ref="H_Sec08_Quest03Ref"/>
        <I_Sec09_Par />
        <J_Sec10_Par />
        <K_Sec11_ImgBig />
        <L_Sec12_Quest04 v-model:answersQuest04="answersQuest04" ref="L_Sec12_Quest04Ref"/>
        <M_Sec13_Par />
        <N_Sec14_ParImgL />
        <O_Sec15_Par />
        <P_Sec16_Quest05 v-model:answersQuest05="answersQuest05" ref="P_Sec16_Quest05Ref"/>
        <Q_Sec17_VideoLink />
        <div v-if="lessonData.lesId" class="button">
        <!-- Modal Trigger -->
        <button data-target="modal1" class="btn modal-trigger right" @click="createAnswerObj()">Verzenden</button>
        </div>
    </div>

  <!-- Modal Structure -->
  <div id="modal1" class="modal">
    <div class="modal-content">
      <h5>Antwoorden les: {{ lessonData.lesId }}</h5>
      <p class="red-text"><i>" Controleer je antwoorden. Klik op verzenden als je tevreden bent. Zo niet, klik op annuleer en pas je antwoorden aan."</i></p>
      <!-- <div>
        <ul>
          <li v-for="question in questions" :key="question.index">{{ question }}</li>
        </ul>
      </div> -->
      <ol>
        <li v-for="answer in answersTotal" :key="answer.index">{{ answer }}</li>
      </ol>
    </div>
    <div class="modal-footer">
      <a href="#!" class="modal-close waves-effect waves-teal btn-flat left" @click.prevent="sendAnswerToDataBase">verzenden</a>
      <a href="#!" class="modal-close waves-effect waves-teal btn-flat">annuleer</a>
    </div>
  </div>
</template>

<script>
import A_Sec01_TitleLesson from "@/components/Lesson/Items/A_Sec01_TitleLesson.vue"
import Objectives from "@/components/Lesson/Items/Objectives.vue"
import B_Sec02_Par from "@/components/Lesson/Items/B_Sec02_Par.vue"
import C_Sec03_Quest01 from "@/components/Lesson/Items/C_Sec03_Quest01.vue"
import D_Sec04_ParImgL from "@/components/Lesson/Items/D_Sec04_ParImgL.vue"
import E_Sec05_Quest02 from "@/components/Lesson/Items/E_Sec05_Quest02.vue"
import F_Sec06_ParImgR from "@/components/Lesson/Items/F_Sec06_ParImgR.vue"
import G_Sec07_Par from "@/components/Lesson/Items/G_Sec07_Par.vue"
import H_Sec08_Quest03 from "@/components/Lesson/Items/H_Sec08_Quest03.vue"
import I_Sec09_Par from "@/components/Lesson/Items/I_Sec09_Par.vue"
import J_Sec10_Par from "@/components/Lesson/Items/J_Sec10_Par.vue"
import K_Sec11_ImgBig from "@/components/Lesson/Items/K_Sec11_ImgBig.vue"
import L_Sec12_Quest04 from "@/components/Lesson/Items/L_Sec12_Quest04.vue"
import M_Sec13_Par from "@/components/Lesson/Items/M_Sec13_Par.vue"
import N_Sec14_ParImgL from "@/components/Lesson/Items/N_Sec14_ParImgL.vue"
import O_Sec15_Par from "@/components/Lesson/Items/O_Sec15_Par.vue"
import P_Sec16_Quest05 from "@/components/Lesson/Items/P_Sec16_Quest05.vue"
import Q_Sec17_VideoLink from "@/components/Lesson/Items/Q_Sec17_VideoLink.vue"

import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

import M from 'materialize-css'
import axios from 'axios'

export default {
  name: "Home",
  components: {
    A_Sec01_TitleLesson ,
    Objectives,
    B_Sec02_Par,
    C_Sec03_Quest01,
    D_Sec04_ParImgL,
    E_Sec05_Quest02,
    F_Sec06_ParImgR,
    G_Sec07_Par,
    H_Sec08_Quest03,
    I_Sec09_Par,
    J_Sec10_Par,
    K_Sec11_ImgBig,
    L_Sec12_Quest04,
    M_Sec13_Par,
    N_Sec14_ParImgL,
    O_Sec15_Par,
    P_Sec16_Quest05,
    Q_Sec17_VideoLink
  },
  data(){
       return {
        message: false,
        questions: [],
        answersQuest01: '',
        answersQuest02: '',
        answersQuest03: '',
        answersQuest04: '',
        answersQuest05: '',
        answersTotal: [],
        answerObj:{
            user: "",
            schoolClass: "",
            educationForm: "",
            lesId: "",
            questions: [],
            answers: []
        },
        inputValue: 'Initial value'
       }
  },
  methods: {
    ...mapActions({

    //    sendAnswer: 'answer/sendAnswer'
    }),
    createAnswerObj(){
        this.collectQuestions()
        this.collectAnswers(this.answersQuest01, this.answersQuest02, this.answersQuest03, this.answersQuest04, this.answersQuest05)
        this.answerObj.user = this.user.data.email,
        this.answerObj.schoolClass = this.user.data.schoolClass,
        this.answerObj.educationForm =  this.user.data.educationForm,
        this.answerObj.lesId = this.lessonData.lesId,
        this.answerObj.questions = this.questions,
        this.answerObj.answers = this.answersTotal
    },
    // HELPER-FUNCTION createAnswerObj()
    collectAnswers(answersQuest01, answersQuest02, answersQuest03, answersQuest04, answersQuest05){
      var arr = []
      if (answersQuest01 !== ""){
        var quest01Arr = answersQuest01.split(/\r?\n/).filter(element => element)
        arr.push(...quest01Arr)
      }
      if (answersQuest02 !== ""){
        var quest02Arr = answersQuest02.split(/\r?\n/).filter(element => element)
        arr.push(...quest02Arr)
      }
      if (answersQuest03 !== ""){
        var quest03Arr = answersQuest03.split(/\r?\n/).filter(element => element)
        arr.push(...quest03Arr)
      }
      if (answersQuest04 !== ""){
        var quest04Arr = answersQuest04.split(/\r?\n/).filter(element => element)
        arr.push(...quest04Arr)
      }
      if (answersQuest05 !== ""){
        var quest05Arr = answersQuest05.split(/\r?\n/).filter(element => element)
        arr.push(...quest05Arr)
      }
      //console.log(arr, "arr")
      this.answersTotal = arr
      //this.toJson(arr)
      return this.answersTotal
    },
    // HELPER-FUNCTION createAnswerObj()
    collectQuestions(){
      var questions = []
      // collect questions from components
      var quest01 = Object.values(this.lessonData.C_Sec03_Quest01)
      var quest02 = Object.values(this.lessonData.E_Sec05_Quest02)
      var quest03 = Object.values(this.lessonData.H_Sec08_Quest03)
      var quest04 = Object.values(this.lessonData.L_Sec12_Quest04)
      var quest05 = Object.values(this.lessonData.P_Sec16_Quest05)
      // append to one array
      const combined = [...quest01, ...quest02, ...quest03, ...quest04, ...quest05]
      // delete empty elements
      for(var i=0; i< combined.length; i++) {
        if(combined[i]) {
             questions.push(combined[i])
        }
      }
      //console.log( questions)
      this.questions = questions
      return this.questions
    },
    async sendAnswerToDataBase(){
        // lesson id in a var
        var LESSON_ID = this.lessonData._id
        // create url for axios
        let url = `/api/v1/lessons/${LESSON_ID}/answers`
        // make request with axios with the answer
        let response = await axios.post(url, this.answerObj)
                        // remove input from user
                        .then(this.clearAnswerInputfields())
                        // make the mesage appear for 3 sec
                        .then(this.sleeper(3000))
                        // go to curriculum page
                        .then(() => {
                            this.$router.replace({
                            name: 'curriculum'
                            })
                        })
    },
    // HELPER-FUNCTION sendAnswerToDataBase()
    // let the message appear and disappear
    sleeper(ms) {
        // make the send message appear
        this.message = true
        return function(x) {
            return new Promise(resolve => setTimeout(() => resolve(x), ms));
        }
    },
    // HELPER-FUNCTION clearAnswerInputfields()
    // removes the input
    clearAnswerInputfields(){
      // create a var "a" to store the input from "C_Sec03_Quest01" component via a ref
      const a = this.$refs.C_Sec03_Quest01Ref.$refs.input_C
        // Check if there is some input to clear by checking undefined
        if(a !== undefined){
          // replace the input with an empty string
          a.value = ""
          // console.log(a)
        }
      const b = this.$refs.E_Sec05_Quest02Ref.$refs.input_E
        if(b !== undefined){
          b.value = ""
          // console.log(b)
        }
      const c = this.$refs.H_Sec08_Quest03Ref.$refs.input_H
        if(c !== undefined){
          c.value = ""
          // console.log(c)
        }
      const d = this.$refs.L_Sec12_Quest04Ref.$refs.input_L
        if(d !== undefined){
          d.value = ""
          // console.log(d)
        }
      const e  = this.$refs.P_Sec16_Quest05Ref.$refs.input_P
        if(e !== undefined){
          e.value = ""
          // console.log(e)
        }
      // replace the given answers with an empty string
      this.answersQuest01 = ""
      this.answersQuest02 = ""
      this.answersQuest03 = ""
      this.answersQuest04 = ""
      this.answersQuest05 = ""
    }
  },
     mounted(){
      M.AutoInit()
  },
    computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData',
      user: 'auth/user'
    })
  }
}
</script>

<style scoped>
.button{
  max-width: 1000px; 
  margin: 0 auto; 
}
.button .btn{
 margin-bottom: 50px;
}
.message{
    margin-top: 300px;
}
</style>




