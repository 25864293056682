<template>
    <section class="lesId">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div class=" A col s12 m8">
                        <div class="">
                            <div class="row">
                                <div class="col s12">
                                <div class="row">
                                    <div class="card" >
                                        <h6 class="card-content">Lesson ID: 3_ASO_th01_les_01</h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
                    </div>
                    <div class="col m6 s12 ">
                         <!-- <div class="input-field col s12 inputFields" >
                            <select :value="educationform" @input='$emit("update:educationform", $event.target.value)'> 
                            <option  value="" disabled selected >Choose your option</option>
                                <option value="1A">1A</option>
                                <option value="2A">2A</option>
                                <option value="1B">1B</option>
                                <option value="2B">2B</option>
                                <option value="3BSO">3BSO</option>
                                <option value="4BSO">4BSO</option>
                                <option value="5BSO">5BSO</option>
                                <option value="6BSO">6BSO</option>
                                <option value="7BSO">7BSO</option>
                                <option value="3TSO">3TSO</option>
                                <option value="4TSO">4TSO</option>
                                <option value="5TSO">5TSO</option>
                                <option value="6TSO">6TSO</option>
                                <option value="3ASO">3ASO</option>
                                <option value="4ASO">4ASO</option>
                                <option value="5ASO">5ASO</option>
                                <option value="6ASO">6ASO</option>
                            </select>
                            <label>Educationform</label>
                    </div> -->
                    <div class="input-field col s12" :value='lesId' @input='$emit("update:lesId", $event.target.value)'>
                        <input id="lesId" type="text" class="validate">
                        <label for="lesId">Lesson ID:</label>
                    </div>

                    <!-- <div class="input-field col s12 inputFields" :value='educationform' @input='$emit("update:educationform", $event.target.value)'>
                        <input id="lesId" type="text" class="validate">
                        <label for="lesId">test:</label>
                    </div> -->

<!-- :value='educationform' @input='$emit("update:educationform", $event.target.value)'  v-model="educationform" -->

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
    lesId: String,
    educationform: String
    },
    data(){
        return {
        lesId:"",
        educationform:"",
        }
    },
    mounted(){
      M.AutoInit()
  }
}
</script>

<style scoped>
.lesId{
    margin-top: -50px;
}
h6{
    font-size: 13px;
    margin-left: -5px;
}
</style>