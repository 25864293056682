<template>

  <!-- unfold all code = crtl + k en ctrl + J -->
  
  <div v-if="message" class="container center message">  
          <div class="">
              <h5>De evaluatie werd succesvol verstuurd</h5>
              <h6>(je word automatisch teruggebracht naar het leerkrachten menu)</h6>
          </div>
      </div>
  
  
    <div class="container test">
   
     
      <div v-if="leerlingenLijst">
        <div class="container-float">
        <a class="btn-floating btn blue-grey darken-3 right tooltipped " data-position="bottom"   data-tooltip="Leerling toevoegen" @click="selectAdd()"><i class="material-icons">add</i></a>
     
      </div>
  
       <div>
        
        <!-- De weergave van de resultaten -->
        <ul class="collapsible with-header popout " >
          <li class=" center collection-header">
              <h5>Leerlingen</h5>
          </li>
        </ul>
  
                <div class="">
                <ul class="collapsible with-header popout">
    
                  <!-- <li class="center collection-header">
                    <h5>{{ educationForm.name }}</h5>
                  </li> -->
                  <li class="divider" tabindex="-1"></li>
     
                  <li v-for="(students, school) in sortedSchools" :key="school">
                    <div class="collapsible-header">{{ school }}</div>
                    <div class="collapsible-body">
                      <div
                        class="collection"
                        v-for="student in students" :key="student._id">
                        
                        <a href="#!" @click="getAwnsersSelectedUser(student)" class="collection-item">
                          <span class="black-text">{{ student.name }}</span> - Richting: {{ student.educationForm  }} - Klas: {{ student.schoolClass }}
                          <!-- <span class="secondary-content black-text">User-Id: <span class="custom-link" id="highlighted-text" @click.stop="selectText(student._id)">{{ student._id }}</span></span> -->
                          <span class="secondary-content custom-link" id="highlighted-text"  @click.stop="selectEdit(student._id, student.name)"><i class=" tiny  material-icons">edit</i></span>
                          <span class="secondary-content custom-link" id="highlighted-text" @click.stop="selectDelete(student._id, student.name )"><i class=" tiny material-icons">delete</i></span>
                        </a>
                      
                      </div>
                    </div>
                  </li>
                </ul>
              </div> 
  
        </div>
  
      </div>
      
      <div v-if="antwoordenLijst">
  
        <div v-if="loadingData">
  <div class="center spinner-offset">
    <div class="preloader-wrapper active">
      <div class="spinner-layer spinner-black-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  </div>
  <div><h6 class="center">Even geduld de gegevens worden opgehaald.</h6></div>
  </div>
  
        <div v-else>
  
  
  <ul class="collection with-header">
    <li class="collection-header center">
      <h5>
        Antwoorden:
        <span class="blue-grey-text">
          <span class="red-text">{{ userName }}</span>
          {{ className }}
          {{ schoolName }}
        </span>
       <span class="right teal-text">{{ averageRating }}</span>
        
      </h5>
    </li>
    <a v-for="antwoord in antwoorden" :key="antwoord.index" href="#!" class="collection-item" @click="antwoord.review && antwoord.review._id ? null : getAwnserAndLesson(antwoord.answerId, getLessonId(antwoord.answerId))">
      <div class="collection-item-wrapper">
        <span class="blue-grey-text left spaced-span">{{ antwoord.lessonTitle }}</span>
        
        <!-- <div v-if="antwoord.review && antwoord.review._id" class="black-text left lesson-title editable-text" contentEditable="true" @click="selectText('{{ antwoord.review._id }}')" style="user-select: text;">Evaluatie ID: {{ antwoord.review._id }} 
        </div>
        <span> answerID: {{ antwoord.answerId }}</span> -->
        <span class="transparant"> lessonID: {{ getLessonId(antwoord.answerId) }}</span>
        <span class="teal-text secondary-content" v-if="antwoord.review">{{ antwoord.review.rating }} <span class="custom-link "  id="highlighted-text" @click.stop="deleteAnswerAndEvaluation(this.userName,  antwoord.lessonTitle, antwoord.answerId, antwoord.review)"><i class=" tiny material-icons">delete</i></span></span>
        <span class="teal-text secondary-content" v-else>Geen evaluatie <span span class="custom-link" id="highlighted-text" @click.stop="deleteAnswerAndEvaluation(this.userName, antwoord.lessonTitle, antwoord.answerId, antwoord.review)"><i class=" tiny material-icons">delete</i></span></span>
        
      </div>
    </a>
  </ul>
          <button  class="btn right" @click="backToStudentList()">Terug</button>
        </div>
      </div>
      <div v-if="evaluatieLijst">
        <div class="row">
      <div class="col s12 ">
        <div class="card ">
          <div class="card-content black-text">
            <span class="card-title">Evaluatie: {{ deLes.A_Sec01_TitleLesson }}</span>
            <p class="red-text">{{ userName }}</p>
          </div>
        </div>
      </div>
    </div>
        <div>
  
          {{ this.deLes }}
          {{ this.singleAnswer.answers}}
        </div>
  
    <div class="row">
      <div class="col s12 ">
        <div class="card ">
          <div class="card-content black-text">
            <span class="card-title">Stap 01: edit</span>
            <p class="red-text">{{ this.questionOne }}</p>
          </div>
        </div>
      </div>
    </div>
  
    <ul class="collection with-header">
              <li class="collection-header center"><h6>Controle antwoorden leerling:</h6></li>
              <a v-for="antwoord in this.singleAnswer.answers" :key="antwoord.index" href="#!" class="collection-item">
              <span>{{ antwoord }}</span>
              </a>
          </ul>  
  
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12" >
            <textarea id="edit-text"  class="materialize-textarea" v-model="textEdit"></textarea>
            <label for="textarea1">Textarea</label>
          </div>
        </div>
      </form>
    </div>
  
     <div class="row">
      <div class="col s12 ">
        <div class="card ">
          <div class="card-content black-text">
            <span class="card-title">Stap 02: string </span>
            <p class="red-text">{{ this.questionTwo }}</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12" >
            <textarea id="comment-string"  class="materialize-textarea" v-model="textCommentString"></textarea>
            <label for="textarea1">Textarea</label>
          </div>
        </div>
      </form>
    </div>
  
    <div class="row">
      <div class="col s12 ">
        <div class="card ">
          <div class="card-content black-text">
            <span class="card-title">Stap 03: gemiddelde</span>
            <p class="red-text">{{ this.questionThree }}</p>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <form class="col s12">
        <div class="row">
          <div class="input-field col s12" >
            <textarea id="avarage-point" class="materialize-textarea" v-model="textAveragePoint"></textarea>
            <label for="textarea1">Textarea</label>
          </div>
        </div>
      </form>
    </div>
        <div>
          <button  class="btn right" @click="evaluate()">Evaluatie</button>
  
        </div>
        <br>
        <br>
      </div>
    </div>
  
  
  
    <!-- Modal edit STUDENT Structure -->
    <div id="modal-edit" class="modal">
      <div class="modal-content">
        <h5>Update leerling: <span class="red-text">{{ userToEdit }}</span></h5>
        <br>
      <div class="row">
        <div class="input-field col s6">
          <p class=" teal-text">Naam:</p>
          <input v-model="editUserName" type="text" id="addUserName" class="small-margin">
          <!-- <label for="editUserName">Naam bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">E-mail:</p>
          <input v-model="editUserEmail" @input="handleInput" type="email" id="addUserEmail" class="small-margin validate">
          <span class="helper-text" v-if="!isEmailValid" data-error="Geen geldige email notatie" ></span>
          <!-- <label for="editUserEmail">E-mail bewerken</label> -->
        </div>
        <!-- <div class="input-field col s6">
          <p class=" teal-text">Wachtwoord:</p>
          <input v-model="editUserPassword" type="text" id="addUserPassword" class="small-margin">
        </div> -->
        <div class="input-field col s6">
          <p class=" teal-text">Rol:</p>
          <input v-model="editUserRole" type="text" id="addUserRole" class="small-margin">
          <!-- <label for="editUserRole">Rol bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">School:</p>
          <input v-model="editUserSchool" type="text" id="addUserSchool" class="small-margin">
          <!-- <label for="editUserSchool">School bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Klas:</p>
          <input v-model="editUserSchoolClass" type="text" id="addUserSchoolClass" class="small-margin">
          <!-- <label for="editUserSchoolClass">Klas bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Starttijd:</p>
          <input v-model="editUserPegoStartTime" type="number" id="addUserPegoStartTime" class="small-margin">
          <!-- <label for="editUserPegoStartTime">Pego Starttijd bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Eindtijd:</p>
          <input v-model="editUserPegoEndTime" type="number" id="addUserPegoEndTime" class="small-margin">
          <!-- <label for="editUserPegoEndTime">Pego Eindtijd bewerken</label> -->
        </div>
        <div class="input-field col s6 ">
          <p class=" teal-text">Opleidingsvorm:</p>
          <!-- <input v-model="addUserEdaddUcationForm" type="text" id="addUserEdaddUcationForm" class="small-margin"> -->
          <!-- <label for="editUserEducationForm">Opleidingsvorm bewerken</label> -->
  
          <select v-model="editUserEducationform" id="addUserEdaddUcationForm" class="browser-default ">
              <!-- <option value="" disabled selected>Opleidingsvorm:</option> -->
              <option value="1A">1A</option>
              <option value="2A">2A</option>
              <option value="1B">1B</option>
              <option value="2B">2B</option>
              <option value="3BSO">3BSO</option>
              <option value="4BSO">4BSO</option>
              <option value="5BSO">5BSO</option>
              <option value="6BSO">6BSO</option>
              <option value="7BSO">7BSO</option>
              <option value="3TSO">3TSO</option>
              <option value="4TSO">4TSO</option>
              <option value="5TSO">5TSO</option>
              <option value="6TSO">6TSO</option>
              <option value="3ASO">3ASO</option>
              <option value="4ASO">4ASO</option>
              <option value="5ASO">5ASO</option>
              <option value="6ASO">6ASO</option>
            </select>
      </div>
      
      <div class="col s6">
  
        <p class="red-text">{{ addUserErrorText }}</p>
        </div>
      
      </div>
          <button @click="editStudent" class="waves-effect  btn right btn-pos">Update</button>
        <br>
      </div>
    </div>
  
  
  <!-- Modal Delete Structure -->
  <div id="modal-delete" class="modal">
     <div class="modal-content">
        <div v-if="loadingData">
           <div class="center ">
              <div class="preloader-wrapper active">
                 <div class="spinner-layer spinner-black-only">
                    <div class="circle-clipper left">
                       <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                       <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                       <div class="circle"></div>
                    </div>
                 </div>
              </div>
           </div>
           <div>
              <h6 class="center">Even geduld de gegevens worden opgehaald.</h6>
           </div>
        </div>
        <div v-else>
           <h5 class="center">!!! VERWIJDER LEERLING: <span class="red-text">{{ userToDelete }}</span> !!!</h5>
           <h6 class="center">Weet u zeker dat u deze leerling wil verwijderen?</h6>
           <p class="center"><i>(inclusief antwoorden en evaluaties)</i></p>
           <div class="button-container center" >
              <a href="#!" class=" modal-close btn blue-grey darken-3 btn-delete" @click="deleteSelectedUser(this.delAnswerIds, this.delReviewIds, selectedUserId)">JA</a>
              <a href="#!" class=" modal-close btn blue-grey darken-3 btn-delete">Nee</a>
              <br>
           </div>
        </div>
     </div>
  </div>
  
    <!-- Modal ADD STUDENT Structure -->
    <div id="modal-add" class="modal">
      <div class="modal-content">
        <h5>Toevoegen leerling:</h5>
        <button @click="resetAddFields" class="waves-effect  btn right add-btn-pos">Reset</button>
        <br>
      <div class="row">
        <div class="input-field col s6">
          <p class=" teal-text">Naam:</p>
          <input v-model="addUserName" type="text" id="addUserName" class="small-margin">
          <!-- <label for="editUserName">Naam bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">E-mail:</p>
          <input v-model="addUserEmail" @input="handleInput" type="email" id="addUserEmail" class="small-margin validate">
          <span class="helper-text" v-if="!isEmailValid" data-error="Geen geldige email notatie" ></span>
          <!-- <label for="editUserEmail">E-mail bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Wachtwoord:</p>
          <input v-model="addUserPassword" type="text" id="addUserPassword" class="small-margin">
          <!-- <label for="editUserPassword">Wachtwoord bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Rol:</p>
          <input v-model="addUserRole" type="text" id="addUserRole" class="small-margin">
          <!-- <label for="editUserRole">Rol bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">School:</p>
          <input v-model="addUserSchool" type="text" id="addUserSchool" class="small-margin">
          <!-- <label for="editUserSchool">School bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Klas:</p>
          <input v-model="addUserSchoolClass" type="text" id="addUserSchoolClass" class="small-margin">
          <!-- <label for="editUserSchoolClass">Klas bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Starttijd:</p>
          <input v-model="addUserPegoStartTime" type="number" id="addUserPegoStartTime" class="small-margin">
          <!-- <label for="editUserPegoStartTime">Pego Starttijd bewerken</label> -->
        </div>
        <div class="input-field col s6">
          <p class=" teal-text">Eindtijd:</p>
          <input v-model="addUserPegoEndTime" type="number" id="addUserPegoEndTime" class="small-margin">
          <!-- <label for="editUserPegoEndTime">Pego Eindtijd bewerken</label> -->
        </div>
        <div class="input-field col s6 ">
          <p class=" teal-text">Opleidingsvorm:</p>
          <!-- <input v-model="addUserEdaddUcationForm" type="text" id="addUserEdaddUcationForm" class="small-margin"> -->
          <!-- <label for="editUserEducationForm">Opleidingsvorm bewerken</label> -->
  
          <select v-model="addUserEducationform" id="addUserEdaddUcationForm" class="browser-default ">
              <!-- <option value="" disabled selected>Opleidingsvorm:</option> -->
              <option value="1A">1A</option>
              <option value="2A">2A</option>
              <option value="1B">1B</option>
              <option value="2B">2B</option>
              <option value="3BSO">3BSO</option>
              <option value="4BSO">4BSO</option>
              <option value="5BSO">5BSO</option>
              <option value="6BSO">6BSO</option>
              <option value="7BSO">7BSO</option>
              <option value="3TSO">3TSO</option>
              <option value="4TSO">4TSO</option>
              <option value="5TSO">5TSO</option>
              <option value="6TSO">6TSO</option>
              <option value="3ASO">3ASO</option>
              <option value="4ASO">4ASO</option>
              <option value="5ASO">5ASO</option>
              <option value="6ASO">6ASO</option>
            </select>
  
    
      </div>
      
      <div class="col s6">
  
        <p class="red-text">{{ addUserErrorText }}</p>
        </div>
      
      </div>
          <button @click="AddStudent" class="waves-effect  btn right btn-pos">Toevoegen</button>
        <br>
      </div>
    </div>
  
  <!-- Modal Delete Answer And Evaluation Structure -->
  <div id="modal-delete-answer" class="modal">
     <div class="modal-content">
        <div v-if="loadingData">
           <div class="center ">
              <div class="preloader-wrapper active">
                 <div class="spinner-layer spinner-black-only">
                    <div class="circle-clipper left">
                       <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                       <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                       <div class="circle"></div>
                    </div>
                 </div>
              </div>
           </div>
           <div>
              <h6 class="center">Even geduld de gegevens worden opgehaald.</h6>
           </div>
        </div>
        <div v-else>
           <h5 class="center"><span class="red-text">{{ answerUserName }}</span></h5>
           <h6 class="center">VERWIJDER DE ANTWOORDEN VAN LES:</h6>
           <h5 class="center"><span class="red-text">{{ answerTitlename }}</span></h5>
            <div v-if="review">
              <!-- <h6 class="center">Wat wil u verwijderen?</h6> -->
              <!-- <p class="center"><i>antwoord ID:<span class="red-text">{{ answerId }}</span></i></p> -->
              <div >
                <p >
                      <label for="checkbox_answers">
                      <input type="checkbox" id="checkbox_answers" class="filled-in" v-model="checkbox_answers" @change="handleCheckboxChange('answers')"/>
                      <span>VERWIJDER ANTWOORDEN EN EVALUATIE</span>
                      </label >
                </p>
                <p >
                      <label for="checkbox_review">
                      <input type="checkbox" id="checkbox_review" class="filled-in" v-model="checkbox_review" @change="handleCheckboxChange('review')"/>
                      <span>VERWIJDER ENKEL EVALUATIE</span>
                      </label >
                </p>
                <p class="red-text">{{ delAnswersErrorText }}</p>
              </div>
            </div>
          <!-- <h6 class="center">Weet u zeker dat u dit wil verwijderen?</h6> -->
  
  
  
           <div class="button-container center" >
              <a href="#!" class=" btn blue-grey darken-3 btn-delete" @click="delAnsRev()">JA</a>
              <a href="#!" class=" modal-close btn blue-grey darken-3 btn-delete">Nee</a>
              <br>
           </div>
        </div>
     </div>
  </div>
  
  
  </template>
  
  <script>
  import { mapGetters, mapActions, mapMutations } from 'vuex';
  import axios from 'axios'
  
  export default {
      data() {
          return {
            userId: "",
            message: false,
            leerlingenLijst: true,
            antwoordenLijst: false,
            evaluatieLijst: false,
            antwoorden: [],
            userName: "",
            lessonIdArray: [],
            deLes: {},
            answerID: "",
            questionOne:"In de volgende tekst staan vragen en antwoorden op deze vragen. noteer de vragen en antwoorden. Beoordeel de antwoorden op basis van de tekst en ken per vraag een cijfer toe op basis van de juistheid van het antwoord waarbij 0 volledig onjuist is en 10 volledig juist. Motiveer je toekenning van het cijfer tussen 0 en 10. Duid te toekenning van het cijfer aan met eerst het woord “punten:”  Een uitzondering is een vraag waarbij gevraagd wordt naar een eigen mening deze moet je niet beoordelen en dus ook geen punten geven. Wanneer er geen antwoord is gegeven op een vraag moet je 0 punten toekennen. Noteer de vraag. Bereken vervolgens het gemiddelde cijfer met 1 cijfer achter de komma van de toegekende punten aan de vragen. Schrijf je beoordeling in de ik vorm. Dit is de tekst: ",
            questionTwo: "Zet de volgende tekst in 1 leesbare string met regeleinden \\n\\n voor een variabele in javascript. Behoud zo veel mogelijk de opmaak. Dus 1 string met regeleinden \\n. Dit is de tekst: ",
            questionThree: "Bereken het gemiddelde aantal punten van de volgende tekst: ",
            textCommentString: "",
            textAveragePoint: "",
            textEdit: "",
            loadingData: true, // Begin met weergeven van de preloader
            className: "",
            schoolName: "",
            selectedUserId: "",
            userToDelete: "",
            userToEdit: "",
            // data variabelen ADD STUDENT
            addUserName:"",
            isEmailValid: true,
            addUserEmail:"",
            addUserPassword:"",
            addUserRole:"",
            addUserSchool:"",
            addUserSchoolClass:"",
            addUserEducationform:"",
            addUserPegoStartTime:0,
            addUserPegoEndTime:0,
            addUserErrorText: "",
            // data variabelen DELETE STUDENT
            delAnswerIds: [],
            delReviewIds: [],
            // Object voor nieuwe leerling
            newUserObj:{
              "name": "",
              "email": "",
              "password": "",
              "role": "",
              "school": "",
              "schoolClass": "",
              "educationForm": "",
              "pegoStartTime": 0,
              "pegoEndTime": 0
            },
            // data variabelen EDIT STUDENT
            editUserName:"",
            editUserEmail:"",
            editUserRole:"",
            editUserSchool:"",
            editUserSchoolClass:"",
            editUserEducationform:"",
            editUserPegoStartTime:0,
            editUserPegoEndTime:0,
            // Object voor nieuwe leerling
            editUserObj:{
              "name": "",
              "email": "",
              "role": "",
              "school": "",
              "schoolClass": "",
              "educationForm": "",
              "pegoStartTime": 0,
              "pegoEndTime": 0
            },
            // data variabelen Delete answer en evaluatie
            answerUserName: "",
            answerTitlename: "",
            answerId: "",
            review: null, // review andere naam voor evaluatie
            checkbox_answers: false,
            checkbox_review: false,
            delAnswersErrorText: "",
            selectedAnswerUser: "",
            // Gemiddelde cijfer
            averageRating: 0
           
            //zet de volgende tekst in 1 leesbare string met regeleinden voor een variabele om later in een html pagina te kunnen gebruiken. dit is de tekst:  
          
           //zet de volgende tekst in 1 leesbare string met regeleinden voor een variabele om later in een html pagina te kunnen gebruiken. behoud zo veel mogelijk de opmaak. dit is de tekst: Hier zijn de vragen en de beoordeling van de antwoorden op basis van de tekst:
          
          // In de volgende tekst staan vragen en antwoorden op deze vragen. noteer de vragen en antwoorden. Beoordeel de antwoorden op basis van de tekst en ken per vraag een cijfer toe op basis van de juistheid van het antwoord waarbij 0 volledig onjuist is en 10 volledig juist. Motiveer je toekenning van het cijfer tussen 0 en 10. Duid te toekenning van het cijfer aan met eerst het woord “punten:”  Een uitzondering is een vraag waarbij gevraagd wordt naar een eigen mening deze moet je niet beoordelen en dus ook geen punten geven. Wanneer er geen antwoord is gegeven op een vraag moet je 0 punten toekennen. Noteer de vraag. Bereken vervolgens het gemiddelde cijfer met 1 cijfer achter de komma van de toegekende punten aan de vragen. Schrijf je beoordeling in de ik vorm. Dit is de tekst:
          
          
          }       
      },
      mounted(){
          //M.AutoInit();
  
          this.initMaterialize()
  
  
             // Roep de getAllUsers-actie aan bij het laden van de pagina
      this.getAllUsers();
      },
      computed: {
          ...mapGetters({
              users: 'evaluate/users',
              lessonTitleAndAnswerIdFromAnswers: 'answer/lessonTitleAndAnswerIdFromAnswers',
              selectedUser: 'evaluate/selectedUser',
              lessonIdFromAnswers: 'answer/lessonIdFromAnswers',
              singleAnswer: 'evaluate/singleAnswer',
              lessonData: 'lesson/lessonData'
  
          }),
  
        // Een berekende eigenschap om de gesorteerde scholen met bijbehorende leerlingen op te halen
        sortedSchools() {
              // Haal de gebruikers op via de getter en sorteer op het veld "school"
              const sortedArray = this.users.slice().sort((a, b) => {
                return a.school.localeCompare(b.school);
              });
  
              // Maak een object waarin elke school wordt gekoppeld aan een array van bijbehorende leerlingen
              const result = {};
              sortedArray.forEach(student => {
                if (!result[student.school]) {
                  result[student.school] = [];
                }
                result[student.school].push(student);
              });
  
              return result;
            }
          

  
      },
      methods:{
          ...mapActions({
              getAllUsers: 'evaluate/getAllUsers',
              getSelectedUser:'evaluate/getSelectedUser',
              getAllAnswersFromUser: 'answer/getAllAnswersFromUser',
              getSingleAnswer: 'evaluate/getSingleAnswer',
              getLesson: 'lesson/getLesson',
              postReview: 'evaluate/postReview',
              deleteSingleReview: 'evaluate/deleteSingleReview',
              deleteAllAnswersFromUser: 'answer/deleteAllAnswersFromUser',
              deleteAllReviewsFromUser: 'evaluate/deleteAllReviewsFromUser',
              deleteUser: 'user/deleteUser',
              createUser: 'user/createUser',
              updateUser: 'user/updateUser',
              deleteSingleAnswer: 'answer/deleteSingleAnswer'
          }),
  //         ...mapMutations({
  //   resetLessonTitleAndAnswerIdFromAnswers: 'answer/RESET_LESSONTITLE_AND_ANSWERID_FROM_ANSWERS'
  // }),
  
      //     test() {
      //       this.resetLessonTitleAndAnswerIdFromAnswers();
      // },
  
          initMaterialize() {
              M.AutoInit();
          },
  
  

          handleCheckboxChange(checkboxType) {
            this.delAnswersErrorText = ""
            if (checkboxType === 'answers' && this.checkbox_answers) {
              this.checkbox_review = false;
            } else if (checkboxType === 'review' && this.checkbox_review) {
              this.checkbox_answers = false;
            }
          },


        






  //              FUNCTIONALITEIT VOOR HET TOEVOEGEN VAN EEN LEERLING
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
          // functie om de modal voor het toevoegen van een leerling te openen
          selectAdd() {
            const modalInstance = M.Modal.getInstance(document.getElementById('modal-add'));
            modalInstance.open();
          },
  
          // functie om de velden van het leerling formulier te wissen
          resetAddFields() {
            this.addUserName = "",
            this.addUserEmail = "",
            // this.isEmailValid = true,
            this.addUserPassword = "",
            this.addUserRole = "",
            this.addUserSchool = "",
            this.addUserSchoolClass = "",
            this.addUserEducationform= "",
            this.addUserPegoStartTime = 0,
            this.addUserPegoEndTime = 0,
            this.addUserErrorText = ""
            console.log("velden zijn leeg")
          },
  
          // functie om een nieuwe leerling toe te voegen
          async AddStudent() {
            // check of alle velden zijn ingevuld
            if (
                this.addUserName === "" ||
                this.addUserEmail === "" ||
                this.addUserPassword === "" ||
                this.addUserRole === "" ||
                this.addUserSchool === "" ||
                this.addUserSchoolClass === "" ||
                this.addUserEducationform  === "" ||
                this.addUserErrorTextn=== "" ||
                this.addUserPegoStartTime === 0 ||
                this.addUserPegoEndTime === 0 
              ) {
                console.log("Niet alle velden zijn ingevuld.");
                this.addUserErrorText = "Fout: Niet alle velden zijn ingevuld."
              // check pego starttjd en pego eindtijd 
              }  else if (this.addUserPegoStartTime > this.addUserPegoEndTime ) {
                console.log("Fout: Starttijd kan niet groter zijn dan eindtijd.");
                this.addUserErrorText = "Fout: De Starttijd kan niet groter zijn dan de eindtijd."
              // check email notatie
              } else if (!this.isEmailValid ){
                console.log("email is niet juist");
              // voeg nieuwe leerling toe
              } else{
                // velden formulier naar object
                this.newUserObj.name = this.addUserName 
                this.newUserObj.email = this.addUserEmail
                this.newUserObj.password = this.addUserPassword
                this.newUserObj.role = this.addUserRole
                this.newUserObj.school = this.addUserSchool
                this.newUserObj.schoolClass = this.addUserSchoolClass
                this.newUserObj.educationForm = this.addUserEducationform
                this.newUserObj.pegoStartTime = this.addUserPegoStartTime
                this.newUserObj.pegoEndTime = this.addUserPegoEndTime 
                console.log("data object create user", this.newUserObj )
                // verstuur de data van de nieuwe leerling naar de database
                await this.createUser(this.newUserObj)
                // vernieuw de lijst met leerlingen
                this.getAllUsers()
                // geef melding van de toegevoegde leerling via een toast bericht
                M.toast({ html: `Leerling: ${ this.addUserName } toegevoegd!` });
                // maak de velden username en email en errortext leeg
                this.addUserErrorText = ""
                this.addUserName = "",
                this.addUserEmail = ""
                // sluit de modal
                const modalInstance = M.Modal.getInstance(document.getElementById('modal-add'));
                modalInstance.close();
                console.log("leerling toegevoegd")
              }
          },
  
  
  
  
          validateEmail(email) {
            // Eenvoudige regex voor e-mailvalidatie
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            this.isEmailValid = emailRegex.test(email);
          },
          handleInput() {
        // Hier kun je aanvullende logica toevoegen als dat nodig is
      },
  
  //
  //                FUNCTIONALITEIT VOOR HET VERWIJDEREN VAN EEN LEERLING
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
              
          // functie om gebruiker te selecteren om te deleten (gekoppeld aan vuilnisemmer icon)
          async selectDelete(userId, username) {
            // Laat de preloader zien
            this.loadingData = true
            // Open de DELETE modal programmatisch
                const modalInstance = M.Modal.getInstance(document.getElementById('modal-delete'));
                modalInstance.open();
                // stel de variabelen in voor weergave van de naam van de leerling en opslaan userID
                var user_Id = userId
                this.selectedUserId = user_Id
                this.userToDelete = username
                console.log('deleted userID', this.selectedUserId );
                console.log('name delted user', username)
                // verzamel de antwoorden en evaluaties van de leerling in this.lessonTitleAndAnswerIdFromAnswers
              
              await this.getAllAnswersFromUser(userId);
                console.log(this.lessonTitleAndAnswerIdFromAnswers)
                // filter de antwoorden en evaluaties uit this.lessonTitleAndAnswerIdFromAnswers en zet ze in arrays
                const answerIds = [];
                const reviewIds = [];
                this.lessonTitleAndAnswerIdFromAnswers.forEach(item => {
                  if (item.answerId) {
                    answerIds.push(item.answerId);
                    }
                  if (item.review && item.review._id) {
                    reviewIds.push(item.review._id);
                    }
                });
  
                this.delAnswerIds = answerIds
                this.delReviewIds = reviewIds 
        
                console.log("answerIds" , this.delAnswerIds);
                console.log("reviewIds" , this.delReviewIds);
  
                // console.log("Voor reset:", this.lessonTitleAndAnswerIdFromAnswers);
                //this.test()
                // console.log("Na reset:", this.lessonTitleAndAnswerIdFromAnswers);
          },
  
          // functie voor het verwijderen van een student (gekoppeld aan de ja-knop delete modal)
          async deleteSelectedUser(delAnswerIds, delReviewIds, userId){
            // verwijder de antwoorden van de leerling
            try {
              await this.deleteAllAnswersFromUser(delAnswerIds);
            } catch (error) {
              console.error('Fout bij het verwijderen van antwoorden:', error);
            }
            // verwijder de evaluaties van de leerling
            try {
              await this.deleteAllAnswersFromUser(delReviewIds);
            } catch (error) {
              console.error('Fout bij het verwijderen van reviews:', error);
            }
            // verwijder de leerling
            try {
              await this.deleteUser(userId);
            } catch (error) {
              console.error('Fout bij het verwijderen van de gebruiker:', error);
            }
            // vernieuw de lijst met leerlingen
            this.getAllUsers()
            // geef melding van de verwijderde leerling via een toast bericht
            M.toast({ html: `Leerling: ${this.userToDelete} verwijderd!` });
          },
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //                FUNCTIONALITEIT VOOR HET UPDATEN VAN EEN LEERLING
  
          // functie om gebruiker te selecteren om te updaten
          async selectEdit(userId, username) {
            var user_Id = userId
            this.selectedUserId = user_Id
            console.log(this.selectedUserId );
            this.userToEdit = username
            // Open de modal programmatisch
            const modalInstance = M.Modal.getInstance(document.getElementById('modal-edit'));
            modalInstance.open();
            await this.getSelectedUser(user_Id)
            console.log("this.selectedUser", this.selectedUser)
            this.editUserName = this.selectedUser.name
            this.editUserEmail = this.selectedUser.email
            this.editUserRole = this.selectedUser.role
            this.editUserSchool = this.selectedUser.school
            this.editUserSchoolClass = this.selectedUser.schoolClass
            this.editUserEducationform = this.selectedUser.educationForm
            this.editUserPegoStartTime = this.selectedUser.pegoStartTime
            this.editUserPegoEndTime = this.selectedUser.pegoEndTime
          },
  
          async editStudent(){
            console.log("edit student")
            // samenstellen object voor nieuwe data voor de te updaten leerling
            this.editUserObj.name = this.editUserName 
            this.editUserObj.email = this.editUserEmail
            this.editUserObj.role = this.editUserRole
            this.editUserObj.school = this.editUserSchool
            this.editUserObj.schoolClass = this.editUserSchoolClass
            this.editUserObj.educationForm = this.editUserEducationform
            this.editUserObj.pegoStartTime = this.editUserPegoStartTime
            this.editUserObj.pegoEndTime = this.editUserPegoEndTime 
            console.log("this.editUserObj", this.editUserObj)
            // url voor de put request om de leerling te updaten in database
            //const apiUrl = 'http://159.223.3.250/api/v1/users'
            const apiUrl = 'http://188.166.72.12/api/v1/users'
            
            try {
              // update de leerling (de request kan alleen bij role: user)
              var response = await axios.put(`${apiUrl}/${this.selectedUserId}` , this.editUserObj);
              console.log("response update user", response)
            } catch (error) {
              console.error('Er is een fout opgetreden bij het updaten van de gebruiker:', error);
            }
            // sluiten van de modal
            const modalInstance = M.Modal.getInstance(document.getElementById('modal-edit'));
            modalInstance.close();
            // updaten van de leerlingen lijst
            this.getAllUsers()
            // melding via een toast
            M.toast({ html: `Leerling: ${this.userToEdit} geupdate` });
          },
  
  
  //                FUNCTIONALITEIT VOOR HET VERWIJDEREN VAN EEN ANTWOORD EN EVALUATIE
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  
          deleteAnswerAndEvaluation(userName, lessonTitle, answerId, review){
            console.log("delete Answer And/Or Evaluation")

            console.log("userName" , userName)
            console.log("lessonTitle" , lessonTitle)
            console.log("answerId" , answerId)
            console.log("review" ,  review)
            this.checkbox_answers = false
            this.checkbox_review = false
            this.answerUserName = userName
            this.answerTitlename = lessonTitle
            this.answerId = answerId
            this.review = review
            // Open de modal programmatisch
            const modalInstance = M.Modal.getInstance(document.getElementById('modal-delete-answer'));
            modalInstance.open();
           
          },
  
  
          async delAnsRev(){
            const modalInstance = M.Modal.getInstance(document.getElementById('modal-delete-answer'));
            console.log("delete answers or/and reviews")
            console.log("checkbox_answers", this.checkbox_answers)
            console.log("checkbox_review", this.checkbox_review)
            if (this.review){
              if (!this.checkbox_answers && !this.checkbox_review){
                console.log("maak een keuze")
                this.delAnswersErrorText = "Maak een keuze!"
              }
              else{
                console.log('er is een evaluatie de antwoorden of evaluatie worden verwijderd')
                modalInstance.close();
                this.delAnswersErrorText = ""
                if (this.checkbox_answers){
                  console.log('De antwoorden en evaluatie worden verwijderd answerID: ', this.answerId + " evaluatieID: " + this.review._id)
                  console.log("userID: ", this.selectedAnswerUser )
                  // TODO VERWIJDER EVALUATIE EN ANTWOORDEN 
                  await this.deleteSingleAnswer(this.answerId)

                  // refresh lijst met antwoorden
                  await this.refreshStudentAnswerlist(this.selectedAnswerUser)
                }else{
                  console.log('enkel de evaluatie wordt verwijderd evaluatieID: ', this.review._id)
                  // VERWIJDER ALLEEN DE EVALUATIE
                  await this.deleteSingleReview(this.review._id)
                  // refresh lijst met antwoorden
                  await this.refreshStudentAnswerlist(this.selectedAnswerUser)
                  modalInstance.close();
                }
              }
            }else{
              console.log('er is geen evaluatie de antwoorden worden verwijderd')
              //  VERWIJDER EVALUATIE EN ANTWOORDEN 
              await this.deleteSingleAnswer(this.answerId)
              // refresh lijst met antwoorden
              await this.refreshStudentAnswerlist(this.selectedAnswerUser)
              modalInstance.close();
            }
          },
  

  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
          // berekend het gemiddelde cijfer wordt opgeroepen in getAwnsersSelectedUser(user)
          async calculateAveragerResult(){
            console.log("calulate Average Result")
            // var voor de gesorteerde antwoorden lijst
            const data = this.antwoorden;
            // lege array om de ratings in op te slaan
            const ratingsArray = [];
            // haal alle ratings uit de antwoordenlijst en stop ze in de lege array
            for (const antwoord of data) {
                if (antwoord.review !== null) {
                    ratingsArray.push(antwoord.review.rating);
                }
            }
            // Nu is ratingsArray gevuld met de beoordelingen
            console.log("Array van ratings:", ratingsArray);
            // Bereken de som van alle getallen in de array
            const sum = ratingsArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            // Bereken het gemiddelde
            const gemiddelde = sum / ratingsArray.length;
            // Formatteer het gemiddelde naar één cijfer achter de komma
            const geformatteerdGemiddelde = gemiddelde.toFixed(1);
            console.log("Gemiddelde:", geformatteerdGemiddelde);
            this.averageRating = geformatteerdGemiddelde
          },
  
  
          async getAwnsersSelectedUser(user) {
            this.leerlingenLijst = false
            this.antwoordenLijst = true
  
            this.loadingData = true
            this.selectedAnswerUser = user._id
            const userId = user._id;
            //console.log(userId);

           

            // Wacht tot getAllAnswersFromUser is voltooid
            await this.getAllAnswersFromUser(userId);
            // Nu kun je de gegevens ophalen
  
            // Roep getSelectedUser aan en wacht tot het is voltooid
            await this.getSelectedUser(userId);
  
            console.log( "geselecteerde user from evaluate component" , this.selectedUser);
            this.userName = this.selectedUser.name
  
            this.className = this.selectedUser.schoolClass
           this.schoolName = this.selectedUser.school
            //console.log(this.lessonTitleAndAnswerIdFromAnswers);
            this.antwoorden = this.lessonTitleAndAnswerIdFromAnswers
            console.log("lessonTile, answerID, review:  ", this.antwoorden)
  
           // Sorteer de lessen/antwoorden 
           this.antwoorden.sort((a, b) => {
              const titleA = a.lessonTitle.toUpperCase();
              const titleB = b.lessonTitle.toUpperCase();
  
              if (titleA < titleB) return -1;
              if (titleA > titleB) return 1;
              return 0;
            });
            console.log("gesorteerde lijst lessonTile, answerID, review:", this.antwoorden)
  
            this.lessonIdArray = this.lessonIdFromAnswers

            await this.calculateAveragerResult()
          },
  //HELPERFUNCTIE
  
          getLessonId(answerId) {
          // Zoek de index van de answerId in de antwoorden array
          const index = this.antwoorden.findIndex(antwoord => antwoord.answerId === answerId);
  
          // Gebruik de index om het bijbehorende lessonId op te halen
          if (index !== -1 && index < this.lessonIdArray.length) {
            return this.lessonIdArray[index];
          }
  
          return 'Niet gevonden'; // Of een standaardwaarde als het niet gevonden wordt
          },
  //HELPERFUNCTIE
          async getAwnserAndLesson(answerId, lessonId) {
            this.leerlingenLijst = false
            this.antwoordenLijst = false
            this.evaluatieLijst = true
            //console.log('answerID:', answerId);
            //console.log('lessonID:', lessonId);
  
            this.answerID = answerId
         
            // Wacht tot getAllAnswersFromUser is voltooid
            await this.getLesson(lessonId);
            // Nu kun je de gegevens ophalen
  
            // Roep getSelectedUser aan en wacht tot het is voltooid
            await this.getSingleAnswer(answerId);
  
            //console.log( "lessonData", this.lessonData);
         
            this.deLes = this.lessonData
            //console.log("singleAnswer", this.singleAnswer);
          },
  

          async refreshStudentAnswerlist(user){
                      this.leerlingenLijst = false
                      this.antwoordenLijst = true
            
                      this.loadingData = true
                    
                      const userId = user
                      console.log(userId);
                      // Wacht tot getAllAnswersFromUser is voltooid
                      await this.getAllAnswersFromUser(userId);

                      this.antwoorden = this.lessonTitleAndAnswerIdFromAnswers
                      console.log("lessonTile, answerID, review:  ", this.antwoorden)
            
                    // Sorteer de lessen/antwoorden 
                    this.antwoorden.sort((a, b) => {
                        const titleA = a.lessonTitle.toUpperCase();
                        const titleB = b.lessonTitle.toUpperCase();
            
                        if (titleA < titleB) return -1;
                        if (titleA > titleB) return 1;
                        return 0;
                      });
                      console.log("gesorteerde lijst lessonTile, answerID, review:", this.antwoorden)
                     await this.calculateAveragerResult()
          },


  
    backToStudentList(){
  
      this.antwoorden = []
      this.antwoordenLijst = 0
      this.leerlingenLijst = 1
      this.userName = ""
      this.loadingData = true
    // Wacht op de volgende tick om ervoor te zorgen dat de DOM is bijgewerkt
    this.$nextTick(() => {
      this.initMaterialize();
    });
  
  
    },
  
    backToAnswerList(){
      this.antwoordenLijst = 1
      this.leerlingenLijst = 0
      //this.question = ""
      this.deLes = null
      this.singleAnswer.answers = null
    },
  
    async evaluate(){
  
      if (this.textCommentString && this.textAveragePoint) { 
      // get data from text fields
      let comment = this.textCommentString
      let avaragePoint = this.textAveragePoint
  
      //console.log("comment", comment)
      //console.log("point", avaragePoint)
  
      const floatNumber = parseFloat(avaragePoint);
  
      // clear text fields
      this.textCommentString = ""
      this.textAveragePoint = ""
      this.textEdit = ""
  
      const answerId = this.answerID // Vervang door de juiste waarde
  
      const reviewData = {
        comment: comment,
        rating: floatNumber
      };
  
      try {
    const response = await this.postReview( { answerId, reviewData });
    // Als er geen fout optreedt, kun je hier eventuele vervolgacties uitvoeren
  
    // display html
    this.antwoordenLijst = false;
    this.leerlingenLijst = false;
    this.evaluatieLijst = false;
    this.message = true;
  
    this.singleAnswer.answers = [];
  
    // Roep de bevestigingstekst op
    await this.sleeper(3000);
  
    // Pas nadat de sleeper is voltooid, ga naar de teacherMenu
    this.$router.replace({
        name: 'teacherMenu'
    });
  } catch (error) {
    // Hier kun je de fout afhandelen, bijvoorbeeld loggen of een melding weergeven aan de gebruiker
    console.error('Fout bij het verzenden van de review:', error);
  
    // Toon een notificatie aan de gebruiker
    // Vervang dit met de juiste code om een notificatie weer te geven
    alert('Er is een fout opgetreden bij het verzenden van de review. Probeer het later opnieuw.');
  }
  
      
  
    } else {
      alert('De velden "string" en gemiddelde zijn leeg');
  }
  
  
    },
  
  
    sleeper(ms) {
      // make the send message appear
      this.message = true;
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  
  
    // async deleteUser(userId){
    //   console.log('deleted user', userId)
    //   await this.getAllAnswersFromUser(userId);
    //   console.log(this.lessonTitleAndAnswerIdFromAnswers)
    // }
  
  
  
  
    },
  
  
   
  
  
        // voor de preloader
  watch: {
      
    lessonTitleAndAnswerIdFromAnswers: {
        handler(newData) {
          console.log("Watcher geactiveerd. newData:", newData);
          // Zet loadingData op false wanneer lessonTitleAndAnswerIdFromAnswers data bevat
          if (newData) {
            this.loadingData = false;
            console.log("Preloader stopt")
          }
        },
        immediate: true, // Zodat de handler meteen wordt uitgevoerd bij het maken van de component
      },
  
      addUserEmail(newValue) {
        console.log("Watcher email geactiveerd");
        this.validateEmail(newValue);
      }
  
  
  
    },
  
  // // voor de preloader
  // watch: {
  //   lessonTitleAndAnswerIdFromAnswers: {
  //     handler(newData) {
  //       // Zet loadingData op false wanneer lessonTitleAndAnswerIdFromAnswers data bevat
  //       if (newData) {
  //         this.loadingData = false;
  //         console.log("Preloader stopt");
  //       } else {
  //         // Zet loadingData op true wanneer lessonTitleAndAnswerIdFromAnswers leeg is
  //         this.loadingData = true;
  //         console.log("Preloader start");
  //       }
  //     },
  //     immediate: true, // Zodat de handler meteen wordt uitgevoerd bij het maken van de component
  //   },
  // },
  

  }
  </script>
  
  <style scoped>
  #modal-add {
    width: 70%; /* Adjust the width as needed */
    max-height: 100vh; /* Adjust the max-height as needed */
    overflow-y: auto; /* Enable vertical scrolling if the content exceeds the height */
    z-index: 1000; /* Adjust the z-index as needed */
  }
  
  
  
  
  
  
  .name-id {
      margin-right: 20px; /* Voeg een rechtermarge toe van 20 pixels tussen de naam en het ID */
    }
  
    .test {
    margin-top: 50px;
  }
  .disabled {
    pointer-events: none;
    cursor: default;
  }
  .transparant {
    opacity: 0;
  }
  .message {
    margin-top: 300px;
  }
  
  .right-align {
    text-align: right;
  }
  .spinner-offset {
      margin-top: 250px;
  }
  .spaced-span {
      margin-right: 10px; /* Voeg meer marges toe zoals gewenst */
  }
  
  .custom-link:hover  {
    text-decoration: underline;
    color: #f80404; /* Kleur van het specifieke deel van de tekst */
  }
  
  #modal-edit .row .input-field p {
    margin-top: -20px !important;
  }
  
  
  #modal-edit .row .input-field input.small-margin {
    margin-top: -30px !important;
  }
  
  
  
  #modal-add .row .input-field p {
    margin-top: -20px !important;
  }
  
  
  #modal-add .row .input-field input.small-margin {
    margin-top: -30px !important;
  }
  
  
  .add-btn-pos{
    margin-top: -50px 
  }
  
  
  
  
  
  
  
  .btn-pos{
    margin-top: -20px 
  }
  
  
  .modal-footer {
      display: flex;
      justify-content: flex-end;
    }
  
  
  .modal-btn {
      margin-left: 10px;
    }
  
  .btn-floating{
    margin-right: 200px ;
  }
  
  
  
  .button-container {
      margin-top: 20px; /* Hier kun je de gewenste marge aanpassen */
    }
  
    .btn-delete {
      margin: 0 20px; /* Hier kun je de gewenste marge aanpassen */
    }
  
    .select-dropdown {
    height: 100px !important;
  }
  
  
  
  
  
  
  
  .container-float {
      /* display: flex;
      justify-content: flex-end;  */
      position: relative;
      height: 5px; /* Stel de hoogte van de container in */
    }
  
    .btn-floating {
      /* margin-top: 50px;
      margin-right: 200px; */
      position: absolute;
      top: 0;
      right: 0;
      margin-right: -50px; 
    }
  
    @media (max-width: 600px) {
      .container-float {
        /* justify-content: flex-start;  */
        right: auto;
        left: 0; /* Verplaats de knop naar links bij kleinere schermen */
        margin-right: 0; /* Reset de negatieve marge */
        
      }
   
    }
  
  
  </style>