<template>
  <div  v-if="lessonData.B_Sec02_Par.title" class="B col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
          <p class="right tag">B</p>
          <h4 >{{ lessonData.B_Sec02_Par.title }}</h4>
          <p class="no-select">{{ lessonData.B_Sec02_Par.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {
   
       }
  },
  mounted(){
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })
  }
}
</script>

<style scoped>
.padding{
  padding: 25px;
}
h4{
  font-weight: bold;
}
.tag{
  color: rgb(255, 255, 255);
}
.B{
  max-width: 1000px;
  margin: 0 auto;
} 
.no-select {
  user-select: none;
}
</style>