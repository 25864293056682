import { createStore } from "vuex";

import lesson from './lesson'
import auth from './auth'
import curriculum from './curriculum'
import answer from './answer'
import user from './user'
import evaluate from './evaluate'

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    lesson,
    auth,
    curriculum,
    answer,
    user,
    evaluate 
  },
});
