<template>
         <div v-if="lessonData.F_Sec06_ParImgR.title" class="F col s12 m8 offset-m2 l6 offset-l3">
        <div class="card padding">
          <div class="row ">
            <div class="col s9">
              <p class="right tag">F</p>
              <h6>{{ lessonData.F_Sec06_ParImgR.title }}</h6>
              <p class="no-select">{{ lessonData.F_Sec06_ParImgR.text }}</p>
            </div>
            <div class="col s3">
              <div class="card">
              <img v-bind:src=lessonData.F_Sec06_ParImgR.imgUrl alt="" class=" materialboxed responsive-img" draggable="false"> 
            </div>
              <div class="card-content black-text center">
                <p class="image-text">{{ lessonData.F_Sec06_ParImgR.imgText }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {

       }
  },
  mounted(){
    M.AutoInit()
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })  
  }
}
</script>

<style  scoped>
.image-text{
font-style: italic;
}
.padding{
  padding: 25px;
}
h6{
  font-weight: bold;
}
.tag{
  color: rgb(255, 255, 255);
}
.F{
  max-width: 1000px;
  margin: 0 auto;
} 
.no-select {
  user-select: none;
}
</style>

