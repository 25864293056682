<template>
  <div class="K col s12 m8 offset-m2 l6 offset-l3">
    <div class="card">
      <div class="row valign-wrapper">
        <div class="col s12">
          <div class="center padding">
            <p class="right tag">K</p>
             <img v-bind:src=imageURL alt="" class=" materialboxed responsive-img">  
          </div>
          <div class="card-content black-text center-align">
            <p class="image-text">{{ imageText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
 props: ['imageText', 'imageURL']
}
</script>

<style scoped>

.image-text{
font-style: italic;
}
.tag{
  color: rgb(0, 0, 0);
}
.K{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>