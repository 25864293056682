<template>
  <div v-if="lessonData.N_Sec14_ParImgL.title" class="N col s12 m8 offset-m2 l6 offset-l3 ">
    <div class="card padding ">
      <div class="row ">
        <div class="col s3">
          <div class="card">
            <img v-bind:src=lessonData.N_Sec14_ParImgL.imgUrl alt="" class=" materialboxed responsive-img" draggable="false"> 
            </div>
            <div class="card-content black-text center">
            <p class="image-text">{{ lessonData.N_Sec14_ParImgL.imgText}}</p>
            </div>
        </div>
        <div class="col s9">
          <p class="right tag">N</p>
          <h6 class="text-left">{{ lessonData.N_Sec14_ParImgL.title }}</h6>
          <p class="no-select">{{ lessonData.N_Sec14_ParImgL.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {

       }
  },
  mounted(){
    M.AutoInit()
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })  
  }
}
</script>

<style scoped>
.padding{
  padding: 25px;
}
.image-text{
font-style: italic;
}
.img{
  padding-top: 50px;
}
h6{
  font-weight: bold;
}
.tag{
  color: rgb(255, 255, 255);
}
.N{
  max-width: 1000px;
  margin: 0 auto;
} 
.no-select {
  user-select: none;
}
</style>