import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue"
import SignIn from "../views/SignIn.vue"
import Curriculum from "../views/Curriculum.vue"
import Lesson from "../views/Lesson.vue"
import Answers from "../views/Answers.vue"
import Create from "../views/Create.vue"
import TeacherMenu from "../views/TeacherMenu.vue"
import Evaluate from "../views/Evaluate.vue"
import UserMenu from "../views/UserMenu.vue"
import store from '@/store'


const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/signin",
    name: "signin",
    component: SignIn,
  },
  {
    path: "/curriculum",
    name: "curriculum",
    component: Curriculum,
    beforeEnter: (to, from, next ) => {
      if (!store.getters['auth/authenticated']){
        return next({
          name: 'signin'
        })
      }

      next()
      
    }
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/lesson",
    name: "lesson",
    component: Lesson
  },
  {
    path: "/answers",
    name: "answers",
    component: Answers
  },
  {
    path: "/create",
    name: "create",
    component: Create,
    beforeEnter: (to, from, next ) => {
      if (!store.getters['auth/authenticated']){
        return next({
          name: 'signin'
        })
      }

      next()
      
    }
  },  
  {
    path: "/teacherMenu",
    name: "teacherMenu",
    component: TeacherMenu,
    beforeEnter: (to, from, next ) => {
      if (!store.getters['auth/authenticated']){
        return next({
          name: 'signin'
        })
      }

      next()
      
    }
  },
  {
    path: "/evaluate",
    name: "evaluate",
    component: Evaluate,
    beforeEnter: (to, from, next ) => {
      if (!store.getters['auth/authenticated']){
        return next({
          name: 'signin'
        })
      }

      next()
      
    }
  },
  {
    path: "/userMenu",
    name: "userMenu",
    component: UserMenu,
    beforeEnter: (to, from, next ) => {
      if (!store.getters['auth/authenticated']){
        return next({
          name: 'signin'
        })
      }

      next()
      
    }
  },
  
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
