<template>
    <section class="C_Sec03_Quest01">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div  class="C col s12 m8 ">
                        <div class="card padding">
                            <div class="row valign-wrapper">
                                <div class="col s12">
                                <p class="right tag">C</p>
                                <h6 class="teal-text"><i>Opdracht:</i></h6>
                                <ul >
                                    <li>vraag 01</li>
                                    <li>vraag 02</li>
                                    <li>vraag 03</li>
                                    <li>vraag 04</li>
                                    <li>vraag 05</li>
                                    <li>vraag 06</li>
                                </ul>
                                <div class="row">
                                    <form class="col s12">
                                        <div class="row">
                                            <div class="input-field col s12" >
                                            <textarea id="textarea_8" class="materialize-textarea" ></textarea>
                                            <label for="textarea_8">(Noteer voor elk antwoord het nummer van de vraag)</label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12">
                    <div class="input-field col s12 " :value='C_Sec03_Quest01_Question01' @input='$emit("update:C_Sec03_Quest01_Question01", $event.target.value)'>
                        <textarea id="textarea_CA" class="materialize-textarea "></textarea>
                        <label for="textarea_CA">Question 1</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='C_Sec03_Quest01_Question02' @input='$emit("update:C_Sec03_Quest01_Question02", $event.target.value)'>
                        <textarea id="textarea_CB" class="materialize-textarea "></textarea>
                        <label for="textarea_CB">Question 2</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='C_Sec03_Quest01_Question03' @input='$emit("update:C_Sec03_Quest01_Question03", $event.target.value)'>
                        <textarea id="textarea_CC" class="materialize-textarea "></textarea>
                        <label for="textarea1_CC">Question 3</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='C_Sec03_Quest01_Question04' @input='$emit("update:C_Sec03_Quest01_Question04", $event.target.value)'>
                        <textarea id="textarea_CD" class="materialize-textarea"></textarea>
                        <label for="textarea_CD">Question 4</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='C_Sec03_Quest01_Question05' @input='$emit("update:C_Sec03_Quest01_Question05", $event.target.value)'>
                        <textarea id="textarea_CE" class="materialize-textarea"></textarea>
                        <label for="textarea_CE">Question 5</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='C_Sec03_Quest01_Question06' @input='$emit("update:C_Sec03_Quest01_Question06", $event.target.value)'>
                        <textarea id="textarea_CF" class="materialize-textarea"></textarea>
                        <label for="textarea_CF">Question 6</label>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
        C_Sec03_Quest01_Question01: String,
        C_Sec03_Quest01_Question02: String,
        C_Sec03_Quest01_Question03: String,
        C_Sec03_Quest01_Question04: String,
        C_Sec03_Quest01_Question05: String,
        C_Sec03_Quest01_Question06: String
    },
    data(){
        return {
            C_Sec03_Quest01_Question01: "",
            C_Sec03_Quest01_Question02: "",
            C_Sec03_Quest01_Question03: "",
            C_Sec03_Quest01_Question04: "",
            C_Sec03_Quest01_Question05: "",
            C_Sec03_Quest01_Question06: ""
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
</style>