<template>
  <!-- <router-link to="/userMenu">Ga naar Component 1</router-link> -->
    <div>
    <switchCurriculum/>
  </div>
  <div>
    <createLesson/>
  </div>
  <div>
    <evaluateAnswers/>
  </div>
</template>

<script>

import switchCurriculum from "@/components/TeacherMenu/switchCurriculum.vue"
import createLesson from "@/components/TeacherMenu/createLesson.vue"
import evaluateAnswers from "@/components/TeacherMenu/evaluateAnswers.vue"

export default {

components:{
    switchCurriculum,
    createLesson,
    evaluateAnswers
}
}
</script>


<style>

</style>



