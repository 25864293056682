<template>
       <div class=" A col s12 m8 offset-m2 l6 offset-l3">
        <div class="">
          <div class="row">
            <div class="col s12">
              <div class="row">
                <div class="card" >
                  <h6>{{ titleLesson }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>


export default {
  props: ['titleLesson']
}
</script>

<style scoped>
h6{
padding: 10px;
}
.A{
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;
} 
</style>