<template>
       <div  class=" C col s12 m8 offset-m2 l6 offset-l3">
        <div class="">
          <div class="row">
            <div class="col s12">
              <div class="row ">
                <div class="card" >
                  <h6 >Doelstellingen:</h6>
                  <ul >
                    <li class="objective">{{ objective_one }}</li>
                    <li class="objective">{{ objective_two }}</li>
                    <li class="objective">{{ objective_three }}</li>
                    <li class="objective">{{ objective_four }}</li>
                    <li class="objective">{{ objective_five }}</li>
                    <li class="objective">{{ objective_six }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>

export default {
  props: ['objective_one', 'objective_two', 'objective_three', 'objective_four', 'objective_five', 'objective_six']

}
</script>

<style  scoped>
h6{
padding: 10px;
}
.C{
  max-width: 1000px;
  margin: 0 auto;
  margin-top: -20px;
 
 
} 
.objective{
 margin-left: 50px;
}

</style>
