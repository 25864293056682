<template>
    <div>
    <updateUser/>
  </div>
  <!-- <div>
    <createLesson/>
  </div>
  <div>
    <evaluateAnswers/>
  </div> -->
</template>

<script>

import updateUser from "@/components/User/updateUser.vue"
// import createLesson from "@/components/TeacherMenu/createLesson.vue"
// import evaluateAnswers from "@/components/TeacherMenu/evaluateAnswers.vue"

export default {

components:{
    updateUser,
}
}
</script>


<style>

</style>




