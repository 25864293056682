<template>
  <div>
    <lessonComponent/>
  </div>
</template>

<script>
import lessonComponent from "@/components/Lesson/lesson.vue"

export default {
    name: 'lesson',
    components:{
      lessonComponent
    }
}
</script>

<style>

</style>