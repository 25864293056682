<template>
  <div v-show="pegoTime" class="container test">
    <div class="">
      <ul class="collapsible with-header popout">
        <!-- <li class=" center collection-header"><h5>{{ educationForm.name }}</h5></li> -->
        <li class="center collection-header">
          <h5>{{ educationForm.name }}</h5>
        </li>
        <li class="divider" tabindex="-1"></li>
        <!-- <li v-for="item in themes" :key="item.index"> -->
        <li v-for="item in educationForm.themes" :key="item.index">
          <div class="collapsible-header">{{ item.theme }}</div>
          <div class="collapsible-body">
            <div
              class="collection"
              v-for="item in item.lessons"
              :key="item.index"
            >
              <a href="#!" @click="getSelectedLesson" class="collection-item"
                >{{ item.les }}
                <span class="transparant">{{ item.lesId }}</span></a
              >
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="message">
    <div class="center message">
      <h5>Je hebt op dit moment geen les!</h5>
      <h6>(Wacht tot je lestijd begint)</h6>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      educationForm: [],
      lesson: '',
      lessonId: '',
      pegoTime: false,
      message: false,
    };
  },
  mounted() {
    M.AutoInit();
    this.pegoTime = this.checkPegoTime(
      this.user.data.pegoStartTime,
      this.user.data.pegoEndTime
    );
    this.getAnswersIdfromLogedInUser();
    this.getCurriculum();
  },
  methods: {
    ...mapActions({
      getLesson: 'lesson/getLesson',
      getAllAnswersFromUser: 'answer/getAllAnswersFromUser',
    }),
    async getCurriculum() {
      let eduForm = this.user.data.educationForm;

      switch (eduForm) {
        case '1A':
          eduForm = '6504213b91d2c2ced093b78e';
          break;
        case '2A':
          eduForm = '63660f355f6e47b827108d23';
          break;
        case '1B':
          eduForm = '6569991b297057515db49b5f'; // !!! WORDT NU GBRUIKT VOOR ILD !!!
          break;
        case '2B':
          eduForm = '';
          break;
        case '3BSO':
          eduForm = '63550366a76b1602403b4311';
          break;
        case '4BSO':
          eduForm = '64ff5c1c91d2c2ced093b59f';
          break;
        case '5BSO':
          eduForm = '634fce2ef11a74c886d54300';
          break;
        case '6BSO':
          eduForm = '63510db0ec9038e8323ddd97';
          break;
        case '7BSO':
          eduForm = '636f42265f6e47b82710950c';
          break;
        case '3TSO':
          eduForm = '64ff13c791d2c2ced093b54d';
          break;
        case '4TSO':
          eduForm = '65322c4c0083c73ac46cbded';
          break;
        case '5TSO':
          eduForm = '634fce49f11a74c886d54347';
          break;
        case '6TSO':
          eduForm = '634fce87f11a74c886d543dd';
          break;
        case '3ASO':
          eduForm = '64f594ff91d2c2ced093b14f';
          break;
        case '4ASO':
          eduForm = '65322beb0083c73ac46cbdc7';
          break;
        case '5ASO':
          eduForm = '634fce0af11a74c886d542b6';
          break;
        case '6ASO':
          eduForm = '635a3929d05506c7d1600d5c';
          break;
      }

      console.log(eduForm);

      let response = await axios.get('/api/v1/curriculums/' + `${eduForm}`);
      this.educationForm = response.data.data.educationForm;
    },
    getAnswersIdfromLogedInUser() {
      // go to store answer and get the answers from the logedin user then check completed lessons
      console.log('shit', this.user.data._id)
      this.getAllAnswersFromUser(this.user.data._id).then(() => {
        this.completedLessons();
        //console.log('shit')
      });
    },
    completedLessons() {
      // make an array from the getter 'answers' object
      let answerAr = Object.values(this.lessonIdFromAnswers);
      // console.log('shit', answerAr)
      // collect all html ellements
      const collection = document.getElementsByClassName('collection-item');
      // itterata trough the collection
      for (let i = 0; i < collection.length; i++) {
        // span from html element with the lessonId in a var
        var element = collection[i].childNodes[1].innerHTML;
        // when the lessonId is also in the answer array
        if (answerAr.includes(element)) {
          // create i element for checkmark
          var checkmark = document.createElement('i');
          // add classes
          checkmark.classList.add('secondary-content', 'material-icons');
          // add checkmark icon
          checkmark.innerHTML = 'check';
          // add new classes to collection-item
          collection[i].classList.add(
            'active',
            'disabled',
            'blue-grey',
            'darken-2'
          );
          // add checkmark to collection-item
          collection[i].appendChild(checkmark);
          //console.log(collection[i])
        }
      }
    },
    getSelectedLesson(e) {
      // get lesId from selected lesson
      this.lessonId = e.target.childNodes[1].innerHTML;
      console.log('lessonId', this.lessonId);
      // if selected lessen is not an empty string
      if (this.lessonId !== ' ') {
        console.log('les is niet leeg');
        // go to store lesson and then open view lesson
        this.getLesson(this.lessonId).then(() => {
          this.$router.replace({
            name: 'lesson',
            //name: 'curriculum'
            // om te testen name: curriculum
          });
        });
      } else {
        // if selected lesson is an empty string
        console.log('les is leeg');
        this.lessonId = 'leeg';
        // go to store lesson and then open view lesson
        this.getLesson(this.lessonId).then(() => {
          this.$router.replace({
            name: 'lesson',
          });
        });
      }
    },
    // function to check  if student has class
    checkPegoTime(pegoStartTime, pegoEndTime) {
      // test
      var currentWeekday = 1;
      // get day of the week [0, 1, 2, 3, 4, 5, 6] 0 = sunday 1 = monday etc...
      //var currentWeekday = moment().weekday()
      // check if the current Weekday is not sunday or saturday (weekend)
      if (!(currentWeekday == 0 || currentWeekday == 6)) {
        console.log('Het is geen weekend');
        // get the time in 24 notation
        var currentTime = moment().format('HH:mm');
        // remove : and convert time in to number
        var timeNumber = parseInt(currentTime.replace(':', ''));
        // check if startTime is smaller then endTime
        if (pegoStartTime < pegoEndTime) {
          // check if currentime is bigger then startime en smaler then endTime
          if (timeNumber > pegoStartTime && timeNumber < pegoEndTime) {
            console.log('leerling heeft les');
            // dont show message
            this.message = false;
            // show curriculum
            return true;
          } else {
            console.log('leerling heeft op dit uur GEEN les');
            // show message
            this.message = true;
            // dont show curriculum
            return false;
          }
        } else {
          // start and endtime pegotime not correct
          console.log('pegoStartTime is bigger then pegoEndTime, not correct');
          // dont show curriculum
          return false;
        }
      } else {
        // it is weekend
        console.log('Het is weekend de leerling heeft geen les');
        // show message
        this.message = true;
        // dont show curriculum
        return false;
      }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      // answersId: 'answer/answersId'
      lessonIdFromAnswers: 'answer/lessonIdFromAnswers',
    }),
  },
};
</script>

<style scoped>
.test {
  margin-top: 50px;
}
.disabled {
  pointer-events: none;
  cursor: default;
}
.transparant {
  opacity: 0;
}
.message {
  margin-top: 300px;
}
</style>
