<template>
       <div v-if="lessonData.A_Sec01_TitleLesson" class=" A col s12 m8 offset-m2 l6 offset-l3">
        <div class="">
          <div class="row">
            <div class="col s12">
              <div class="row">
                <div class="card" >
                  <h6>{{ lessonData.A_Sec01_TitleLesson }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {
      
  }
 },
  mounted(){

  },
  methods:{

  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })
  }
}
</script>

<style scoped>
h6{
padding: 10px;
}
.A{
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;
} 
</style>