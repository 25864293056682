<template>
    <section class="D_Sec04_ParImgL">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div  class="D col s12 m8  ">
                        <div class="card padding ">
                            <div class="row ">
                                <div class="col s3">
                                <div class="card">
                                    <img src="~@/assets/background.jpg" alt="" class=" materialboxed responsive-img"> 
                                </div>
                                <div class="card-content black-text center">
                                    <p class="image-text"></p>
                                </div>
                                </div>
                                <div class="col s9">
                                <p class="right tag">D</p>
                                <h6 class="text-left">Title</h6>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam eos vitae officia, esse similique autem.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12">
                        <div class="input-field col s12 " :value='D_Sec04_ParImgL_Title' @input='$emit("update:D_Sec04_ParImgL_Title", $event.target.value)'>
                            <input id="D_Sec04_ParImgL_Title" type="text" class="validate">
                            <label for="D_Sec04_ParImgL_Title"> Title Paragraph: </label>
                        </div>
                        <div class="input-field col s12 inputFields" :value='D_Sec04_ParImgL_Text' @input='$emit("update:D_Sec04_ParImgL_Text", $event.target.value)'>
                            <textarea id="D_Sec04_ParImgL_Text" class="materialize-textarea"></textarea>
                            <label for="D_Sec04_ParImgL_Text">Text:</label>
                        </div>
                        <div class="input-field col s12 inputFields" :value='D_Sec04_ParImgL_Image_Text' @input='$emit("update:D_Sec04_ParImgL_Image_Text", $event.target.value)'>
                            <input id="D_Sec04_ParImgL_Image_Text" type="text" class="validate">
                            <label for="D_Sec04_ParImgL_Image_Text"> Image Text: </label>
                        </div>
                        <div class="input-field col s12 inputFields" :value='  D_Sec04_ParImgL_Image_URL' @input='$emit("update:D_Sec04_ParImgL_Image_URL", $event.target.value)'>
                            <input id="D_Sec04_ParImgL_Image_URL" type="url" class="validate">
                            <label for="D_Sec04_ParImgL_Image_URL"> Image URL: </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
        D_Sec04_ParImgL_Title: String,
        D_Sec04_ParImgL_Text: String,
        D_Sec04_ParImgL_Image_Text: String,
        D_Sec04_ParImgL_Image_URL: String,
    },
    data(){
        return {
            D_Sec04_ParImgL_Title: "",
            D_Sec04_ParImgL_Text: "",
            D_Sec04_ParImgL_Image_Text: "",
            D_Sec04_ParImgL_Image_URL: "",
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
</style>