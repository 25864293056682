<template>
  <div class="H col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
           <p class="right tag">H</p>
          <h6 class="teal-text"><i>Opdracht:</i></h6> 
          <ul >
            <li>{{ quest03_one }}</li>
            <li>{{ quest03_two }}</li>
            <li>{{ quest03_three }}</li>
            <li>{{ quest03_four }}</li>
            <li>{{ quest03_five }}</li>
            <li>{{ quest03_six }}</li>
          </ul>
          <div class="row">
            <form class="col s12">
              <div class="row">
                <div class="input-field col s12" >
                  <textarea id="textarea1" class="materialize-textarea"></textarea>
                  <label for="textarea1">(Noteer voor elk antwoord het nummer van de vraag)</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['quest03_one', 'quest03_two', 'quest03_three', 'quest03_four', 'quest03_five', 'quest03_six']
}
</script>

<style  scoped>
.padding{
  padding: 25px;
}
.tag{
  color: rgb(0, 0, 0);
}
.H{
  max-width: 1000px;
  margin: 0 auto;
} 

</style>