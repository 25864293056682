<template>
  <div v-if="lessonData.Q_Sec17_VideoLink.videoTitleOne" class="Q col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
          <p class="right tag">Q</p>
            <h6><i>Links:</i></h6> 
            <ul >
              <li><a v-bind:href=lessonData.Q_Sec17_VideoLink.videoOneUrl target="_blank" class="collection-item teal-text">{{ lessonData.Q_Sec17_VideoLink.videoTitleOne }}</a></li>
              <li><a v-bind:href=lessonData.Q_Sec17_VideoLink.videoTwoUrl target="_blank" class="collection-item teal-text">{{ lessonData.Q_Sec17_VideoLink.videoTitleTwo }}</a></li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {
   
       }
  },
  mounted(){
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })
  }
}
</script>

<style scoped>
.padding{
  padding: 25px;
}
.tag{
  color: rgb(255, 255, 255);
}
.Q{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>