<template>
<div class="container test">

    <ul class="collapsible with-header popout " >
        <li class=" center collection-header">
            <h5>ANTWOORDEN <span class="right teal-text" id="average-rating"> <h6>{{ averageRating }}</h6></span></h5>
            
        </li>
    </ul>

    <div v-if="loadingData">
<div class="center spinner-offset">
  <div class="preloader-wrapper active">
    <div class="spinner-layer spinner-black-only">
      <div class="circle-clipper left">
        <div class="circle"></div>
      </div>
      <div class="gap-patch">
        <div class="circle"></div>
      </div>
      <div class="circle-clipper right">
        <div class="circle"></div>
      </div>
    </div>
  </div>
</div>
<div><h6 class="center">Even geduld de gegevens worden opgehaald.</h6></div>
</div>

    <div v-if="showAnswerList">

    <ul class="collapsible with-header popout " >
        <!-- <li class=" center collection-header">
            <h5>ANTWOORDEN</h5>
        </li> -->
        <li>
    <div v-for="item in lessonTitleAndAnswerIdFromAnswers" :key="item.index" class="collection">
      <div>
        <a href="#!" @click="getSelectedAnswer(item.answerId)" class="collection-item">
          {{ item.lessonTitle }} <span class="transparant">{{ item.answerId }}</span>
          <span  class="black-text secondary-content" v-if="item.review">{{ item.review.rating }}</span>
          <span class="black-text secondary-content" v-else>Geen evaluatie</span>
        </a>
      </div>
    </div>
  </li>
    </ul>
    </div>
    <div v-if="showSingleAnswer" class="">
    <div class="card-panel ">
        
        <div class="row">
            <div class="card-panel ">
                <div>
                    <button  class="btn right button-margin" @click.prevent="returnToAnwserList">Terug</button>
                </div>
               <div>
                    <button  data-target="modal1" class="btn right modal-trigger button-margin" @click.prevent="">Evaluatie</button>
               </div>
                <p class=" name"> {{ user.data.name }}</p>
                <br>
                <div>
                    <h6 > {{ singleAnswerlessonTitle }}</h6>
                </div>
            </div>
        </div>
        <div v-for="item in singleAnswer" :key="item.index">
            <ul>
                <li class="up">
                <p class="teal-text">{{ item.question }}</p>
                    <div class="row">
                        <div class="card-panel blue-grey darken-3">
                            <span class="white-text">Antwoord {{ item.answer }}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    </div>
</div>

  <!-- Modal Structure -->
  <div id="modal1" class="modal">
    <div class="modal-content">
      <h6>Evaluatie les: {{ singleAnswerlessonTitle }}</h6>
      <p class=" " v-html="review"></p>
    </div>
    <div class="modal-footer">
      <!-- <a href="#!" class="modal-close waves-effect waves-teal btn-flat left" @click.prevent="">verzenden</a>
      <a href="#!" class="modal-close waves-effect waves-teal btn-flat">annuleer</a> -->
    </div>
  </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            answerId:"",
            showAnswerList: false,
            showSingleAnswer: false,
            review: "",
            formattedReview: '',
            loadingData: true, // Begin met weergeven van de preloader
            // Gemiddelde cijfer
            averageRating: 0
        }       
    },
    mounted(){
        M.AutoInit()
        console.log("poep", this.lessonTitleAndAnswerIdFromAnswers)
         // Check if all getters are available after the component is mounted
        //  if (this.showAnswerList){
        //   
        //  }
        
       

    },
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            user: 'auth/user',
            lessonTitleAndAnswerIdFromAnswers: 'answer/lessonTitleAndAnswerIdFromAnswers',
            singleAnswer: 'answer/singleAnswer',
            singleAnswerlessonTitle: 'answer/singleAnswerlessonTitle'
        }),
       
    },
    methods:{
        ...mapActions({
            getSingleAnswer: 'answer/getSingleAnswer'
           
        }),

        getSelectedAnswer(answerId) {
            if (answerId) {
                console.log("Antwoord is niet leeg", answerId);
                this.getSingleAnswer(answerId);
                this.specificComment(answerId)
                this.showAnswerList = false;
                this.showSingleAnswer = true;
            } else {
                console.log("Antwoord is leeg");
                this.lessonId = "leeg";
            }
        },

        specificComment(answerId) {
          console.log("Dit zou answerId moeten zijn", answerId);
          // Haal de specifieke evaluatie op uit de array
          const answerIdToFilter = answerId;
          const filteredReview = this.lessonTitleAndAnswerIdFromAnswers.filter(obj => obj.answerId === answerIdToFilter);

          if (filteredReview.length > 0 && filteredReview[0].review && filteredReview[0].review.comment !== null) {
              const review = filteredReview[0].review.comment;
              const formattedText = review.replace(/\\n/g, '<br>');
              this.review = formattedText;
          } else {
              // Als de review niet gevonden wordt of null is, kun je hier een fallback-actie uitvoeren
              this.review = "Er is voor deze les nog geen evaluatie beschikbaar";
          }
        },

            // berekend het gemiddelde cijfer wordt opgeroepen in getAwnsersSelectedUser(user)
            calculateAveragerResult(){
            console.log("calulate Average Result")
            // var voor de gesorteerde antwoorden lijst
            const data = this.lessonTitleAndAnswerIdFromAnswers;
            // lege array om de ratings in op te slaan
            const ratingsArray = [];
            // haal alle ratings uit de antwoordenlijst en stop ze in de lege array
            for (const antwoord of data) {
                if (antwoord.review !== null) {
                    ratingsArray.push(antwoord.review.rating);
                }
            }
            // Nu is ratingsArray gevuld met de beoordelingen
            console.log("Array van ratings:", ratingsArray);
            // Bereken de som van alle getallen in de array
            const sum = ratingsArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            // Bereken het gemiddelde
            const gemiddelde = sum / ratingsArray.length;
            // Formatteer het gemiddelde naar één cijfer achter de komma
            const geformatteerdGemiddelde = gemiddelde.toFixed(1);
            console.log("Gemiddelde:", geformatteerdGemiddelde);
            this.averageRating = geformatteerdGemiddelde
          },

        // checkGetterAvailable() {
        //   if( this.lessonTitleAndAnswerIdFromAnswers !== undefined){
        //     this.calculateAveragerResult()
        //     }
        // },

        //"Er werd nog geen evaluatie voor deze les gevonden."
        returnToAnwserList(){
            console.log('hallo')
            this.showAnswerList = true
            this.showSingleAnswer = false
        },
    },
    // voor de preloader
    watch: {
    lessonTitleAndAnswerIdFromAnswers: {
      handler(newData) {
        // Zet loadingData op false wanneer lessonTitleAndAnswerIdFromAnswers data bevat
        if (newData) {
          this.loadingData = false;

        // Sorteer de data op de titel van de lessen 
        this.lessonTitleAndAnswerIdFromAnswers.sort((a, b) => {
                    const titleA = a.lessonTitle.toUpperCase();
                    const titleB = b.lessonTitle.toUpperCase();

                    if (titleA < titleB) return -1;
                    if (titleA > titleB) return 1;
                    return 0;
              });

          this.showAnswerList = true;
          this.calculateAveragerResult()
        }
      },
      immediate: true, // Zodat de handler meteen wordt uitgevoerd bij het maken van de component
    },
  
  
    //lessonTitleAndAnswerIdFromAnswers: 'checkGetterAvailable'
  
  
  },




}
</script>

<style scoped>
.test{
    margin-top: 50px;
}

.up{
    margin-top: -25px;
}

.name{
    margin-top: -10px;
}

.kut{
    padding-bottom: 50px;
}

#average-rating{
  margin-right: 10px;
  margin-top: -5px;
}

.transparant{
    opacity: 0; 
}
.indent{
    text-indent: 50px;
}
.button-margin {
    margin-right: 20px; /* Pas dit aan naar de gewenste afstand */
}
.formatted-review {
  white-space: pre-line;
}
.spinner-offset {
    margin-top: 100px;
}
</style>