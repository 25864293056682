<template>
  <div class="container test">
 <div class="card">
  <div class="container ">
    <div class="row ">
 <div class="card-content ">
      <div class="card card-background">
          <h5 class="center  ">Lessenreeks</h5>
        <div class="col m6 s12 ">
          <h6 class="textTitle">Kies studierichting:</h6>
                 <div v-if="message">
                  <br>
                  <h6 class="red-text center">Selecteer een studierichting!</h6>
                </div>
        </div>
      </div>
      <div class="col m6 s12">
        <div class="input-field col s12 ">
          <select v-model="newCurParams.educationForm">
            <option value="" disabled selected>Studierichtingen</option>
            <option value="1A">1A</option>
            <option value="2A">2A</option>
            <option value="1B">1B</option>
            <option value="2B">2B</option>
            <option value="3BSO">3BSO</option>
            <option value="4BSO">4BSO</option>
            <option value="5BSO">5BSO</option>
            <option value="6BSO">6BSO</option>
            <option value="7BSO">7BSO</option>
            <option value="3TSO">3TSO</option>
            <option value="4TSO">4TSO</option>
            <option value="5TSO">5TSO</option>
            <option value="6TSO">6TSO</option>
            <option value="3ASO">3ASO</option>
            <option value="4ASO">4ASO</option>
            <option value="5ASO">5ASO</option>
            <option value="6ASO">6ASO</option>
            <option value="1B">6A_ILD</option>
          </select>
          <label></label>
          <div>
            <div class="row">
                <a href="#!" class="btn right textTitle" @click.prevent="changeCurriculum">Bevestig </a>
         
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>
</template>

<script>

// acces to vue store
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      // var for user input
      newCurParams: {
        educationForm: "",
        user: ""
      },
      // var message when there is no "studierichting" selected
      message: false
    }
  },
  mounted() {
    // acivate materialize css
    M.AutoInit()
  },
  methods:{
    // get the needed functions from vue store
    ...mapActions({
        switchCurriculum: 'user/switchCurriculum',
        signOutAction: 'auth/signOut'
    }),
    // function to change the curriculum 
    changeCurriculum(){
      // check if a "studierichting" is selected
        if(this.newCurParams.educationForm){
          // message must disappear
          this.message = false
          // add the id from the loged in user in to key/value pair of newCurParams
          this.newCurParams.user = this.user.data._id
          // call function "switchCurriculum" from user store and pass newCurParams then log out an go to "Home" view
          this.switchCurriculum(this.newCurParams).then(this.signOutAction().then(() => { this.$router.replace({ name: 'home'})}))
        } 
        else {
          // message must apear
          this.message = true
        }
    }
  },
  computed: {
    // get the needed getters from the vue store
    ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
    })
  },
  // realtime watch the "message" var
  watch:{
  // watch a key/value pair educationForm from the newCurParams var
   'newCurParams.educationForm'(newVal){
    // if the key/value pair educationForm changes in to true 
    if(newVal){
      // message must disappear
      this.message = false
    }
   }
  }
}
</script>

<style scoped>
.test{
  margin-top: 50px;
}
.textTitle{
  margin-top: 30px;
}
.card-background{
  background: #f2f2f2;
}
</style>




