<template>
      <div class="E col s12 m8 offset-m2 l6 offset-l3">
        <div class="card padding">
          <div class="row valign-wrapper">
            <div class="col s12">
              <p class="right tag">E</p>
                <h6 class="teal-text"><i>Opdracht:</i></h6> 
                    <ul >
                      <li>{{ quest02_one }}</li>
                      <li>{{ quest02_two }}</li>
                      <li>{{ quest02_three }}</li>
                      <li>{{ quest02_four }}</li>
                      <li>{{ quest02_five }}</li>
                      <li>{{ quest02_six }}</li>
                    </ul>
                <div class="row">
                  <form class="col s12">
                    <div class="row">
                      <div class="input-field col s12" >
                        <textarea id="textarea1" class="materialize-textarea" ></textarea>
                        <label for="textarea1">(Noteer voor elk antwoord het nummer van de vraag)</label>
                      </div>
                    </div>
                  </form>
                </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>


export default {
  props: ['quest02_one', 'quest02_two', 'quest02_three', 'quest02_four', 'quest02_five', 'quest02_six']

}
</script>

<style  scoped>
.padding{
  padding: 25px;
}
.tag{
  color: rgb(0, 0, 0);
}
.E{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>