<template>
       <div v-if="lessonData.D_Sec04_ParImgL.title" class="D col s12 m8 offset-m2 l6 offset-l3 ">
        <div class="card padding ">
          <div class="row ">
            <div class="col s3">
              <div class="card">
                <img v-bind:src=lessonData.D_Sec04_ParImgL.imgUrl alt="" class=" materialboxed responsive-img" draggable="false"> 
                </div>
                <div class="card-content black-text center">
                <p class="image-text">{{ lessonData.D_Sec04_ParImgL.imgText }}</p>
                </div>
            </div>
            <div class="col s9">
               <p class="right tag ">D</p>
              <h6 class="text-left">{{ lessonData.D_Sec04_ParImgL.title }}</h6>
              <p class="no-select">{{ lessonData.D_Sec04_ParImgL.text }}</p>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {

       }
  },
  mounted(){
     M.AutoInit()
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })  
  }
}
</script>

<style scoped>
.padding{
  padding: 25px;
}
h6{
  font-weight: bold;
}
.border{
  border:1px solid black;
}
.tag{
  color: rgb(255, 255, 255);
}
.D{
  max-width: 1000px;
  margin: 0 auto;
} 
.no-select {
  user-select: none;
}
</style>
