<template>
  <!-- Dropdown Structure -->
  <!-- <div>

  
<ul id="dropdown_2" class="dropdown-content">
  <li ><router-link :to="{name:'create'}" class="black-text">Create</router-link></li>
   <li class="divider"></li>
  <li><a href="#!" class="black-text">Change</a></li>
  <li class="divider"></li>
  <li><a href="#!" class="black-text">three</a></li>
</ul>
</div> -->

      <div class="navbar">
        <nav class="blue-grey darken-3">
            <div class="container">
                <div class="nav-wrapper">
                    <a href="./" class="brand-logo">PEGO / ild</a>
                    <a href="#" data-target="mobile-demo" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                    <ul class="right hide-on-med-and-down">
                        <template v-if="authenticated">
                            <li><router-link :to="{name:'home'}">Home</router-link></li>
                            <li><router-link :to="{name:'curriculum'}">Lessen</router-link></li>
                            <li><router-link :to="{name:'answers'}">Antwoorden</router-link></li>
                            <li v-if="user" class=" amber-text">{{ user.data.name }}</li>
                            <li v-if="admin"><router-link :to="{name:'teacherMenu'}">Lkr. Menu</router-link></li>
                            <li><a href="#" @click.prevent="signOut">Afmelden</a></li>
                            <!-- <li v-if="admin"><a class="dropdown-trigger"  href="#!" data-trigger="dropdown_2">Admin Menu<i class="material-icons right">arrow_drop_down</i></a></li> -->
                    
                        </template>
                        <template v-else>
                            <li><router-link :to="{name: 'signin'}">Aanmelden</router-link></li>
                        </template>
                    </ul>
                </div>
            </div>
            
        </nav>
    </div>
    <ul class="sidenav" id="mobile-demo">
        
          <!-- <template v-if="authenticated">
                            <li><router-link :to="{name:'dashboard'}">Dashboard</router-link></li>
                            <li><router-link :to="{name:'home'}">Home</router-link></li>
                            <li v-if="user">{{ user.data.name }}</li>
                            <li><a href="#" @click.prevent="signOut">Sign out</a></li>
                        </template>
                        <template v-else>
                            <li><router-link :to="{name: 'signin'}">Sign in</router-link></li>
                        </template> -->
    </ul>
</template>

<script>
import M from 'materialize-css'
import { mapGetters, mapActions } from 'vuex'

export default {
  data(){
       return {
       }
  },
  mounted () {
    M.AutoInit()
  },
  computed: {
    ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
    }),
    admin(){
     
            if (this.user.data.role === 'admin'){
            return true
        }else{
            return false
        }
      
    }
  },
  methods: {
    ...mapActions({
        signOutAction: 'auth/signOut'
    }),
    signOut() {
        this.signOutAction().then(() => {
            this.$router.replace({
                name: 'home'
            })
        })
    },
    
  }
}
</script>

<style>

</style>