<template>
<transition name="fade" appear>
  <div class="home-background">
    <div class="primary-overlay">
    <div class="showcase container">
      <div class="row">
        <div class="col s12 main-text">
          <!-- <h2>Protestants Evangelisch Godsdienst Onderwijs</h2> -->
          <p class="flow-text"><i>"Samen geloven, samen leren en samen leven"</i></p>
        </div>
      </div>
    </div>
  </div>
  </div>
</transition>
</template>

<script>

import M from 'materialize-css'

export default {
  name: 'home',
  components: {
  
  },
  mounted () {
    M.AutoInit()
  }
}
</script>

<style scoped>
.home-background {
  background: url('~@/assets/background.jpg');
  background-size: cover;
  background-position: center;
  height: calc(100vh - 64px);
  min-height: 800px;
  color: #fff;
  position: relative;
}
.primary-overlay {
  background: rgba(63, 57, 77, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.main-text{
  margin-top: 100px;
}
/* fade transitions */
.fade-enter-from{
  opacity: 0;
}
.fade-enter-to{
  opacity: 1;
}
.fade-enter-active{
  transition: all 3s ease;
}
</style>


