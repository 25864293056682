<template>
  <div>
    <createComponent/>
  </div>
</template>

<script>
import createComponent from "@/components/Create/Create.vue"

export default {
    name: 'create',
    components:{
      createComponent
    }
}
</script>

<style>

</style>