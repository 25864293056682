<template>
  <section class="F_Sec06_ParImgR">
   <div class="container">
      <div class="row">
         <div>
            <div class="col m6 s12">
               <div class="F col s12 m8 ">
                  <div class="card padding">
                     <div class="row ">
                        <div class="col s9">
                           <p class="right tag">F</p>
                           <h6>Title</h6>
                           <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellendus tempore eos ullam, laudantium vitae sunt.</p>
                        </div>
                        <div class="col s3">
                           <div class="card">
                              <img src="~@/assets/background.jpg" alt="" class=" materialboxed responsive-img"> 
                           </div>
                           <div class="card-content black-text center">
                              <p class="image-text"></p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col m6 s12">
               <div class="input-field col s12 inputFields" :value='F_Sec06_ParImgR_Title' @input='$emit("update:F_Sec06_ParImgR_Title", $event.target.value)'>
                  <input id="F_Sec06_ParImgR_Title" type="text" class="validate">
                  <label for="F_Sec06_ParImgR_Title"> Title Paragraph: </label>
               </div>
               <div class="input-field col s12 inputFields" :value='F_Sec06_ParImgR_Text' @input='$emit("update:F_Sec06_ParImgR_Text", $event.target.value)'>
                  <textarea id="F_Sec06_ParImgR_Text" class="materialize-textarea"></textarea>
                  <label for="F_Sec06_ParImgR_Text">Text:</label>
               </div>
               <div class="input-field col s12 inputFields" :value='F_Sec06_ParImgR_Image_Text' @input='$emit("update:F_Sec06_ParImgR_Image_Text", $event.target.value)'>
                  <input id="F_Sec06_ParImgR_Image_Text" type="text" class="validate">
                  <label for="F_Sec06_ParImgR_Image_Text"> Image Text: </label>
               </div>
               <div class="input-field col s12 inputFields" :value='  F_Sec06_ParImgR_Image_URL' @input='$emit("update:F_Sec06_ParImgR_Image_URL", $event.target.value)'>
                  <input id="F_Sec06_ParImgR_Image_URL" type="url" class="validate">
                  <label for="F_Sec06_ParImgR_Image_URL"> Image URL: </label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
    prop: {
        F_Sec06_ParImgR_Title: String,
        F_Sec06_ParImgR_Text: String,
        F_Sec06_ParImgR_Image_Text: String,
        F_Sec06_ParImgR_Image_URL: String,
    },
    data(){
        return {
            F_Sec06_ParImgR_Title: "",
            F_Sec06_ParImgR_Text: "",
            F_Sec06_ParImgR_Image_Text: "",
            F_Sec06_ParImgR_Image_URL: "",
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
</style>