<template>
    <div>
      <evaluateComponent/>
    </div>
  </template>
  
  <script>
  import evaluateComponent from "@/components/Evaluate/evaluate.vue"
  
  export default {
      name: 'evaluate',
      components:{
        evaluateComponent
      }
  }
  </script>
  
  <style>
  
  </style>