import axios from 'axios'


export default{
  namespaced: true,

  state: {
    users: [],
    selectedUser: {},
    singleAnswer: {},
    //apiUrl: 'http://159.223.3.250/api/v1/reviews/',
    apiUrl: 'http://188.166.72.12/api/v1/reviews/',
    deleteResponses: null,
    deleteError: null,
    singleReviewDeleteResponse: null,
    singleReviewDeleteError: null
  },
  getters: {
    users(state){
      return state.users
    },
    selectedUser(state) {
      return state.selectedUser
    },
    singleAnswer(state){
      return state.singleAnswer
    }
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users.slice(); // Maak een kopie van de array om de originele volgorde niet te verstoren
      state.users.sort((a, b) => {
        if (a.school === "level-X3" && b.school !== "level-X3") {
          return -1;
        } else if (a.school !== "level-X3" && b.school === "level-X3") {
          return 1;
        } else if (a.school === "ka1" && b.school !== "ka1") {
          return -1;
        } else if (a.school !== "ka1" && b.school === "ka1") {
          return 1;
        } else {
          return 0;
        }
      });
    },
    SET_SELECTED_USER(state, userData){
      state.selectedUser = userData
    },
    SET_SINGLE_ANSWER(state, answerData){
      state.singleAnswer = answerData
    },
    SET_DELETE_RESPONSES(state, responses) {
      state.deleteResponses = responses;
    },
    SET_DELETE_ERROR(state, error) {
      state.deleteError = error;
    },
    SET_DELETE_SINGLE_REVIEW_RESPONSE(state, response){
      state.singleReviewDeleteResponse = response;
    },
    SET_DELETE_SINGLE_REVIEW_RESPONSE(state, error){
      state.singleReviewDeleteError = error;
    }
  
  },
  

  actions: {
    async getAllUsers({ commit }) {
      let response = await axios.get('/api/v1/users')
      console.log("store-evaluate leerlingen lijst", response.data.data);
        try {
          commit('SET_USERS', response.data.data)
          
        } catch (error) {
          console.log('Error: Er werden geen users gevonden', error)
        }
      },
      async getSelectedUser({ commit }, userId) {
        try {
          let response = await axios.get('/api/v1/users/' + userId);
          console.log(" response getSelectedUser method from evaluate store", response.data.data)
          commit('SET_SELECTED_USER', response.data.data);
        } catch (error) {
          console.log('Error: Er werd geen user gevonden', error);
        }
      },
      async getSingleAnswer({ commit }, answerId) {
          try {
            let response = await axios.get('/api/v1/answers/'+ answerId)
            console.log("single answer", response.data.data);
            commit('SET_SINGLE_ANSWER', response.data.data);
          } catch (error) {
            console.log('Error: Er werden geen antwoorden gevonden', error);
          }
        },

        async postReview({ commit }, { answerId, reviewData }) {
          try {
            // Voer hier je HTTP-request uit
            const response = await axios.post(`/api/v1/answers/${answerId}/reviews`, reviewData);
            // Voer eventuele verdere acties uit met de response, bijvoorbeeld committen naar state
            // commit('SET_REVIEW', response.data);
            console.log(response.data);
        
            return response; // Geef de response door voor het geval je deze elders wilt gebruiken
          } catch (error) {
            // Behandel eventuele fouten hier
            console.error('Fout bij het uitvoeren van POST-verzoek:', error);
            throw error; // Gooi de fout opnieuw zodat deze in de component kan worden opgevangen
          }
        },

        async deleteAllReviewsFromUser({ commit, state }, recordIdsToDelete) {
          try {
            const deleteRequests = recordIdsToDelete.map(recordId => {
              return axios.delete(`${state.apiUrl}/${recordId}`);
            });
      
            const responses = await Promise.all(deleteRequests);
            
            commit('SET_DELETE_RESPONSES', responses);
          } catch (error) {
            commit('SET_DELETE_ERROR', error);
          }
        },

        async deleteSingleReview({ commit, state }, reviewId) {
          try {
            // Voer het DELETE-verzoek uit om de gebruiker te verwijderen
            await axios.delete(`${state.apiUrl}/${reviewId}`);
        
            // Als het verwijderen succesvol is, commit de bijbehorende mutation
            commit('SET_DELETE_SINGLE_REVIEW_RESPONSE', reviewId);
          } catch (error) {
            console.error('Er is een fout opgetreden bij het verwijderen van een gebruiker:', error);
            // Als er een fout optreedt, commit de fout om deze af te handelen in de component
            commit('SET_DELETE_SINGLE_REVIEW_ERROR', error);
          }
        }

  }
}