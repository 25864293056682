<template>
  <div>
    <answersComponent/>
  </div>
</template>

<script>
import answersComponent from "@/components/Answers/answers.vue"

export default {
    name: 'answers',
    components:{
      answersComponent
    }
}
</script>

<style>

</style>