<template>
    <div>
      <userMenuComponent/>
    </div>
  </template>
  
  <script>
  import userMenuComponent from "@/components/User/userMenu.vue"
  
  export default {
      name: 'userMenu',
      components:{
        userMenuComponent
      }
  }
  </script>
  
  <style>
  
  </style>