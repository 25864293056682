<template>

<div v-if="message" class="container center message">  
   <div class="">
      <h5>De les werd succesvol verstuurd</h5>
      <h6>(je word automatisch teruggebracht naar de vorige pagina)</h6>
   </div>
</div>
<div v-else-if="errorMessage" class="container center message">  
   <div class="">
      <h5>Er is iets fout gegaan in het verzenden van de nieuwe les</h5>
      <h6 class="red-text">{{ this.requestError }}</h6>
     
      <p>(Gebruik "preview" om de fout mogelijk te herstellen. Dit bericht verdwijnt over 10 sec)</p>
   </div>
</div>
<div v-else>

   <div class="container">
      <div class="row">
        <div class="card center">
          <h5>LESSON</h5>
        </div>
      </div>
   </div>


      <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12"><h5>Educationform:  </h5></div>
               </div>
               <div class="col m6 s12 ">
            
            <div class="input-field col s12 " >
                            <select v-model="educationform"> 
                            <option  value="" disabled selected >Choose your option</option>
                                <option value="1A">1A</option>
                                <option value="2A">2A</option>
                                <option value="1B">1B</option>
                                <option value="2B">2B</option>
                                <option value="3BSO">3BSO</option>
                                <option value="4BSO">4BSO</option>
                                <option value="5BSO">5BSO</option>
                                <option value="6BSO">6BSO</option>
                                <option value="7BSO">7BSO</option>
                                <option value="3TSO">3TSO</option>
                                <option value="4TSO">4TSO</option>
                                <option value="5TSO">5TSO</option>
                                <option value="6TSO">6TSO</option>
                                <option value="3ASO">3ASO</option>
                                <option value="4ASO">4ASO</option>
                                <option value="5ASO">5ASO</option>
                                <option value="6ASO">6ASO</option>
                            </select>
                            <label>Educationform</label>
                    </div>
            
            
            
            
            </div>
      </div>
   </div>
 
<div>
   <lesId 
      v-model:lesId="lesId"
      v-model:educationform="educationform"
   />
</div>

<div>
   <A_Sec01_TitleLesson v-model:A_Sec01_TitleLesson="A_Sec01_TitleLesson"/>
</div>
<div>
   <Objectives
      v-model:Objectives_Objective01="Objectives_Objective01"
      v-model:Objectives_Objective02="Objectives_Objective02"
      v-model:Objectives_Objective03="Objectives_Objective03"
      v-model:Objectives_Objective04="Objectives_Objective04"
      v-model:Objectives_Objective05="Objectives_Objective05"
      v-model:Objectives_Objective06="Objectives_Objective06"
   />
</div>











   <div class="container">
      <div class="row">
        <div class="card center">
          <h5>ITEMS</h5>
        </div>
      </div>
   </div>
<div class="container checkboxes">
   <div class="row">
      <div class="col m3 s12 ">
         <div class="container ">
            <p>
               <label for="checkbox_B_Sec02_Par">
               <input type="checkbox" id="checkbox_B_Sec02_Par" class="filled-in" v-model="checkbox_B_Sec02_Par" />
               <span>B_Sec02_Par</span>
               </label >
            </p>
            <p>
               <label for="checkbox_C_Sec03_Quest01" >
               <input type="checkbox" id="checkbox_C_Sec03_Quest01" class="filled-in" v-model="checkbox_C_Sec03_Quest01" />
               <span>C_Sec03_Quest01</span>
               </label>
            </p>
            <p>
               <label for="checkbox_D_Sec04_ParImgL">
               <input type="checkbox" id="checkbox_D_Sec04_ParImgL" class="filled-in" v-model="checkbox_D_Sec04_ParImgL" />
               <span>D_Sec04_ParImgL</span>
               </label>
            </p>
            <p>
               <label for="checkbox_E_Sec05_Quest02">
               <input type="checkbox" id="checkbox_E_Sec05_Quest02" class="filled-in"  v-model="checkbox_E_Sec05_Quest02"/>
               <span>E_Sec05_Quest02</span>
               </label>
            </p>
         </div>
      </div>
      <div class="col m3 s12">
         <div class="container">
            <p>
               <label for="checkbox_F_Sec06_ParImgR">
               <input type="checkbox" id="checkbox_F_Sec06_ParImgR" class="filled-in" v-model="checkbox_F_Sec06_ParImgR"/>
               <span>F_Sec06_ParImgR</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_G_Sec07_Par" class="filled-in" v-model="checkbox_G_Sec07_Par"/>
               <span>G_Sec07_Par</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_H_Sec08_Quest03" class="filled-in" v-model="checkbox_H_Sec08_Quest03"/>
               <span>H_Sec08_Quest03</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_I_Sec09_Par" class="filled-in" v-model="checkbox_I_Sec09_Par"/>
               <span>I_Sec09_Par</span>
               </label>
            </p>
         </div>
      </div>
      <div class="col m3 s12">
         <div class="container">
            <p>
               <label>
               <input type="checkbox" id="checkbox_J_Sec10_Par" class="filled-in" v-model="checkbox_J_Sec10_Par"/>
               <span>J_Sec10_Par</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_K_Sec11_ImgBig" class="filled-in" v-model="checkbox_K_Sec11_ImgBig"/>
               <span>K_Sec11_ImgBig</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_L_Sec12_Quest04" class="filled-in" v-model="checkbox_L_Sec12_Quest04" />
               <span>L_Sec12_Quest04</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_M_Sec13_Par" class="filled-in" v-model="checkbox_M_Sec13_Par"/>
               <span>M_Sec13_Par</span>
               </label>
            </p>
         </div>
      </div>
      <div class="col m3 s12">
         <div class="container">
            <p>
               <label>
               <input type="checkbox" id="checkbox_N_Sec14_ParImgL" class="filled-in" v-model="checkbox_N_Sec14_ParImgL"/>
               <span>N_Sec14_ParImgL</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_O_Sec15_Par" class="filled-in" v-model="checkbox_O_Sec15_Par"/>
               <span>O_Sec15_Par</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_O_Sec15_Par" class="filled-in" v-model="checkbox_P_Sec16_Quest05"/>
               <span>P_Sec16_Quest05</span>
               </label>
            </p>
            <p>
               <label>
               <input type="checkbox" id="checkbox_Q_Sec17_VideoLink" class="filled-in" v-model="checkbox_Q_Sec17_VideoLink" />
               <span>Q_Sec17_VideoLink</span>
               </label>
            </p>
         </div>
      </div>
   </div>
</div>
<div v-if="checkbox_B_Sec02_Par">
   <B_Sec02_Par 
      v-model:B_Sec02_Par_Title="B_Sec02_Par_Title" 
      v-model:B_Sec02_Par_Text="B_Sec02_Par_Text"
   />
</div>
<div v-if="checkbox_C_Sec03_Quest01">
   <C_Sec03_Quest01 
      v-model:C_Sec03_Quest01_Question01="C_Sec03_Quest01_Question01"
      v-model:C_Sec03_Quest01_Question02="C_Sec03_Quest01_Question02"
      v-model:C_Sec03_Quest01_Question03="C_Sec03_Quest01_Question03"
      v-model:C_Sec03_Quest01_Question04="C_Sec03_Quest01_Question04"
      v-model:C_Sec03_Quest01_Question05="C_Sec03_Quest01_Question05"
      v-model:C_Sec03_Quest01_Question06="C_Sec03_Quest01_Question06"
   />
</div>
<div v-if="checkbox_D_Sec04_ParImgL">
   <D_Sec04_ParImgL
      v-model:D_Sec04_ParImgL_Title="D_Sec04_ParImgL_Title"
      v-model:D_Sec04_ParImgL_Text="D_Sec04_ParImgL_Text"
      v-model:D_Sec04_ParImgL_Image_Text="D_Sec04_ParImgL_Image_Text"
      v-model:D_Sec04_ParImgL_Image_URL="D_Sec04_ParImgL_Image_URL"
   />
</div>

<div v-if="checkbox_E_Sec05_Quest02">
   <E_Sec05_Quest02 
      v-model:E_Sec05_Quest02_Question01="E_Sec05_Quest02_Question01"
      v-model:E_Sec05_Quest02_Question02="E_Sec05_Quest02_Question02"
      v-model:E_Sec05_Quest02_Question03="E_Sec05_Quest02_Question03"
      v-model:E_Sec05_Quest02_Question04="E_Sec05_Quest02_Question04"
      v-model:E_Sec05_Quest02_Question05="E_Sec05_Quest02_Question05"
      v-model:E_Sec05_Quest02_Question06="E_Sec05_Quest02_Question06"
   />
</div>
<div v-if="checkbox_F_Sec06_ParImgR">
   <F_Sec06_ParImgR
      v-model:F_Sec06_ParImgR_Title="F_Sec06_ParImgR_Title"
      v-model:F_Sec06_ParImgR_Text="F_Sec06_ParImgR_Text"
      v-model:F_Sec06_ParImgR_Image_Text="F_Sec06_ParImgR_Image_Text"
      v-model:F_Sec06_ParImgR_Image_URL="F_Sec06_ParImgR_Image_URL"
   />
</div>
<div v-if="checkbox_G_Sec07_Par">
   <G_Sec07_Par
      v-model:G_Sec07_Par_Title="G_Sec07_Par_Title" 
      v-model:G_Sec07_Par_Text="G_Sec07_Par_Text"
   />
</div>
<div v-if="checkbox_H_Sec08_Quest03">
   <H_Sec08_Quest03 
      v-model:H_Sec08_Quest03_Question01="H_Sec08_Quest03_Question01"
      v-model:H_Sec08_Quest03_Question02="H_Sec08_Quest03_Question02"
      v-model:H_Sec08_Quest03_Question03="H_Sec08_Quest03_Question03"
      v-model:H_Sec08_Quest03_Question04="H_Sec08_Quest03_Question04"
      v-model:H_Sec08_Quest03_Question05="H_Sec08_Quest03_Question05"
      v-model:H_Sec08_Quest03_Question06="H_Sec08_Quest03_Question06"
   />
</div>
<div v-if="checkbox_I_Sec09_Par">
   <I_Sec09_Par
      v-model:I_Sec09_Par_Title="I_Sec09_Par_Title" 
      v-model:I_Sec09_Par_Text="I_Sec09_Par_Text"
   />
</div>
<div v-if="checkbox_J_Sec10_Par">
   <J_Sec10_Par
      v-model:J_Sec10_Par_Title="J_Sec10_Par_Title" 
      v-model:J_Sec10_Par_Text="J_Sec10_Par_Text"
   />
</div>
<div v-if="checkbox_K_Sec11_ImgBig">
   <K_Sec11_ImgBig
      v-model:K_Sec11_ImgBig_Image_Text="K_Sec11_ImgBig_Image_Text"
      v-model:K_Sec11_ImgBig_Image_URL="K_Sec11_ImgBig_Image_URL"
   />
</div>
<div v-if="checkbox_L_Sec12_Quest04">
   <L_Sec12_Quest04 
      v-model:L_Sec12_Quest04_Question01="L_Sec12_Quest04_Question01"
      v-model:L_Sec12_Quest04_Question02="L_Sec12_Quest04_Question02"
      v-model:L_Sec12_Quest04_Question03="L_Sec12_Quest04_Question03"
      v-model:L_Sec12_Quest04_Question04="L_Sec12_Quest04_Question04"
      v-model:L_Sec12_Quest04_Question05="L_Sec12_Quest04_Question05"
      v-model:L_Sec12_Quest04_Question06="L_Sec12_Quest04_Question06"
   />
</div>
<div v-if="checkbox_M_Sec13_Par">
   <M_Sec13_Par
      v-model:M_Sec13_Par_Title="M_Sec13_Par_Title" 
      v-model:M_Sec13_Par_Text="M_Sec13_Par_Text"
   />
</div>
<div v-if="checkbox_N_Sec14_ParImgL">
   <N_Sec14_ParImgL
      v-model:N_Sec14_ParImgL_Title="N_Sec14_ParImgL_Title"
      v-model:N_Sec14_ParImgL_Text="N_Sec14_ParImgL_Text"
      v-model:N_Sec14_ParImgL_Image_Text="N_Sec14_ParImgL_Image_Text"
      v-model:N_Sec14_ParImgL_Image_URL="N_Sec14_ParImgL_Image_URL"
   />
</div>
<div v-if="checkbox_O_Sec15_Par">
   <O_Sec15_Par
      v-model:O_Sec15_Par_Title="O_Sec15_Par_Title" 
      v-model:O_Sec15_Par_Text="O_Sec15_Par_Text"
   />
</div>
<div v-if="checkbox_P_Sec16_Quest05">
   <P_Sec16_Quest05 
      v-model:P_Sec16_Quest05_Question01="P_Sec16_Quest05_Question01"
      v-model:P_Sec16_Quest05_Question02="P_Sec16_Quest05_Question02"
      v-model:P_Sec16_Quest05_Question03="P_Sec16_Quest05_Question03"
      v-model:P_Sec16_Quest05_Question04="P_Sec16_Quest05_Question04"
      v-model:P_Sec16_Quest05_Question05="P_Sec16_Quest05_Question05"
      v-model:P_Sec16_Quest05_Question06="P_Sec16_Quest05_Question06"
   />
</div>
<div v-if="checkbox_Q_Sec17_VideoLink">
   <Q_Sec17_VideoLink
      v-model:Q_Sec17_VideoLink_Video_One_Title="Q_Sec17_VideoLink_Video_One_Title" 
      v-model:Q_Sec17_VideoLink_Video_One_Url="Q_Sec17_VideoLink_Video_One_Url"
      v-model:Q_Sec17_VideoLink_Video_Two_Title="Q_Sec17_VideoLink_Video_Two_Title" 
      v-model:Q_Sec17_VideoLink_Video_Two_Url="Q_Sec17_VideoLink_Video_Two_Url"
   />
</div>




<!-- Modal Trigger -->
<div class="container">
   <div class="row">
      <div class="card center">
          <button data-target="modal1" class="btn modal-trigger right" @click="createLessonObj()">Preview</button>
      </div>
   </div>
</div>     
        

</div> 





<!-- <p>{{ A_Sec01_TitleLesson }}</p>

<p>{{ Objectives_Objective01 }}</p>
<p>{{ Objectives_Objective02 }}</p>
<p>{{ Objectives_Objective03 }}</p>
<p>{{ Objectives_Objective04 }}</p>
<p>{{ Objectives_Objective05 }}</p>
<p>{{ Objectives_Objective06 }}</p>

<p>{{ B_Sec02_Par_Title }}</p>
<p>{{ B_Sec02_Par_Text }}</p>

<p>{{ C_Sec03_Quest01_Question01 }}</p>
<p>{{ C_Sec03_Quest01_Question02 }}</p>
<p>{{ C_Sec03_Quest01_Question03 }}</p>
<p>{{ C_Sec03_Quest01_Question04 }}</p>
<p>{{ C_Sec03_Quest01_Question05 }}</p>
<p>{{ C_Sec03_Quest01_Question06 }}</p>
    
<p>{{ D_Sec04_ParImgL_Title }}</p>
<p>{{ D_Sec04_ParImgL_Text }}</p>
<p>{{ D_Sec04_ParImgL_Image_Text }}</p>
<p>{{ D_Sec04_ParImgL_Image_URL }}</p>

<p>{{ E_Sec05_Quest02_Question01 }}</p>
<p>{{ E_Sec05_Quest02_Question02 }}</p>
<p>{{ E_Sec05_Quest02_Question03 }}</p>
<p>{{ E_Sec05_Quest02_Question04 }}</p>
<p>{{ E_Sec05_Quest02_Question05 }}</p>
<p>{{ E_Sec05_Quest02_Question06 }}</p>

<p>{{ F_Sec06_ParImgR_Title }}</p>
<p>{{ F_Sec06_ParImgR_Text }}</p>
<p>{{ F_Sec06_ParImgR_Image_Text }}</p>
<p>{{ F_Sec06_ParImgR_Image_URL }}</p>

<p>{{ G_Sec07_Par_Title }}</p>
<p>{{ G_Sec07_Par_Text }}</p>

<p>{{ H_Sec08_Quest03_Question01 }}</p>
<p>{{ H_Sec08_Quest03_Question02 }}</p>
<p>{{ H_Sec08_Quest03_Question03 }}</p>
<p>{{ H_Sec08_Quest03_Question04 }}</p>
<p>{{ H_Sec08_Quest03_Question05 }}</p>
<p>{{ H_Sec08_Quest03_Question06 }}</p>

<p>{{ I_Sec09_Par_Title }}</p>
<p>{{ I_Sec09_Par_Text }}</p>

<p>{{ J_Sec10_Par_Title }}</p>
<p>{{ J_Sec10_Par_Text }}</p>

<p>{{ K_Sec11_ImgBig_Image_Text }}</p>
<p>{{ K_Sec11_ImgBig_Image_URL }}</p>

<p>{{ L_Sec12_Quest04_Question01 }}</p>
<p>{{ L_Sec12_Quest04_Question02 }}</p>
<p>{{ L_Sec12_Quest04_Question03 }}</p>
<p>{{ L_Sec12_Quest04_Question04 }}</p>
<p>{{ L_Sec12_Quest04_Question05 }}</p>
<p>{{ L_Sec12_Quest04_Question06 }}</p>

<p>{{ M_Sec13_Par_Title }}</p>
<p>{{ M_Sec13_Par_Text }}</p>

<p>{{ N_Sec14_ParImgL_Title }}</p>
<p>{{ N_Sec14_ParImgL_Text }}</p>
<p>{{ N_Sec14_ParImgL_Image_Text }}</p>
<p>{{ N_Sec14_ParImgL_Image_URL }}</p>

<p>{{ O_Sec15_Par_Title }}</p>
<p>{{ O_Sec15_Par_Text }}</p>

<p>{{ P_Sec16_Quest05_Question01 }}</p>
<p>{{ P_Sec16_Quest05_Question02 }}</p>
<p>{{ P_Sec16_Quest05_Question03 }}</p>
<p>{{ P_Sec16_Quest05_Question04 }}</p>
<p>{{ P_Sec16_Quest05_Question05 }}</p>
<p>{{ P_Sec16_Quest05_Question06 }}</p>

<p>{{ Q_Sec17_VideoLink_Video_One_Title }}</p>
<p>{{ Q_Sec17_VideoLink_Video_One_Url }}</p>
<p>{{ Q_Sec17_VideoLink_Video_Two_Title }}</p>
<p>{{ Q_Sec17_VideoLink_Video_Two_Url }}</p> -->


  <!-- Modal Structure -->
  <div id="modal1" class="modal">
    <div class="modal-content">
      <h5>PREVIEW LESSON: </h5>
      <prev_A_Sec01_TitleLesson :titleLesson="this.A_Sec01_TitleLesson" />
      <prev_Objectives :objective_one="this.Objectives_Objective01" :objective_two="this.Objectives_Objective02" :objective_three="this.Objectives_Objective03" :objective_four="this.Objectives_Objective04" :objective_five="this.Objectives_Objective05" :objective_six="this.Objectives_Objective06" />
      <div v-if="checkbox_B_Sec02_Par">
         <prev_B_Sec02_Par :titleParagraph="this.B_Sec02_Par_Title" :text="this.B_Sec02_Par_Text" />
      </div>
      <div v-if="checkbox_C_Sec03_Quest01">
         <prev_C_Sec03_Quest01 :quest01_one="this.C_Sec03_Quest01_Question01" :quest01_two="this.C_Sec03_Quest01_Question02" :quest01_three="this.C_Sec03_Quest01_Question03" :quest01_four="this.C_Sec03_Quest01_Question04" :quest01_five="this.C_Sec03_Quest01_Question05" :quest01_six="this.C_Sec03_Quest01_Question06" />
      </div>
      <div v-if="checkbox_D_Sec04_ParImgL">
         <prev_D_Sec04_ParImgL :titleParagraph="this.D_Sec04_ParImgL_Title" :text="this.D_Sec04_ParImgL_Text" :imageText="this.D_Sec04_ParImgL_Image_Text" :imageURL="this.D_Sec04_ParImgL_Image_URL"/>
      </div>
      <div v-if="checkbox_E_Sec05_Quest02">
         <prev_E_Sec05_Quest02 :quest02_one="this.E_Sec05_Quest02_Question01" :quest02_two="this.E_Sec05_Quest02_Question02" :quest02_three="this.E_Sec05_Quest02_Question03" :quest02_four="this.E_Sec05_Quest02_Question04" :quest02_five="this.E_Sec05_Quest02_Question05" :quest02_six="this.E_Sec05_Quest02_Question06" />
      </div>
      <div v-if="checkbox_F_Sec06_ParImgR">
         <prev_F_Sec06_ParImgR :titleParagraph="this.F_Sec06_ParImgR_Title" :text="this.F_Sec06_ParImgR_Text" :imageText="this.F_Sec06_ParImgR_Image_Text" :imageURL="this.F_Sec06_ParImgR_Image_URL"/>
      </div>
      <div v-if="checkbox_G_Sec07_Par">
         <prev_G_Sec07_Par :titleParagraph="this.G_Sec07_Par_Title" :text="this.G_Sec07_Par_Text" />
      </div>
      <div v-if="checkbox_H_Sec08_Quest03">
         <prev_H_Sec08_Quest03 :quest03_one="this.H_Sec08_Quest03_Question01" :quest03_two="this.H_Sec08_Quest03_Question02" :quest03_three="this.H_Sec08_Quest03_Question03" :quest03_four="this.H_Sec08_Quest03_Question04" :quest03_five="this.H_Sec08_Quest03_Question05" :quest03_six="this.H_Sec08_Quest03_Question06" />
      </div>
      <div v-if="checkbox_I_Sec09_Par">
         <prev_I_Sec09_Par :titleParagraph="this.I_Sec09_Par_Title" :text="this.I_Sec09_Par_Text" />
      </div>
      <div v-if="checkbox_J_Sec10_Par">
         <prev_J_Sec10_Par :titleParagraph="this.J_Sec10_Par_Title" :text="this.J_Sec10_Par_Text" />
      </div>
      <div v-if="checkbox_K_Sec11_ImgBig">
         <prev_K_Sec11_ImgBig :imageText="this.K_Sec11_ImgBig_Image_Text" :imageURL="this.K_Sec11_ImgBig_Image_URL"/>
      </div>
      <div v-if="checkbox_L_Sec12_Quest04">
         <prev_L_Sec12_Quest04 :quest04_one="this.L_Sec12_Quest04_Question01" :quest04_two="this.L_Sec12_Quest04_Question02" :quest04_three="this.L_Sec12_Quest04_Question03" :quest04_four="this.L_Sec12_Quest04_Question04" :quest04_five="this.L_Sec12_Quest04_Question05" :quest04_six="this.L_Sec12_Quest04_Question06" />
      </div>
      <div v-if="checkbox_M_Sec13_Par">
       <prev_M_Sec13_Par :titleParagraph="this.M_Sec13_Par_Title" :text="this.M_Sec13_Par_Text" />
      </div>
      <div v-if="checkbox_N_Sec14_ParImgL">
         <prev_N_Sec14_ParImgL :titleParagraph="this.N_Sec14_ParImgL_Title" :text="this.N_Sec14_ParImgL_Text" :imageText="this.N_Sec14_ParImgL_Image_Text" :imageURL="this.N_Sec14_ParImgL_Image_URL"/>
      </div>
      <div v-if="checkbox_O_Sec15_Par">
         <prev_O_Sec15_Par :titleParagraph="this.O_Sec15_Par_Title" :text="this.O_Sec15_Par_Text" />
      </div>
      <div v-if="checkbox_P_Sec16_Quest05">
       <prev_P_Sec16_Quest05 :quest05_one="this.P_Sec16_Quest05_Question01" :quest05_two="this.P_Sec16_Quest05_Question02" :quest05_three="this.P_Sec16_Quest05_Question03" :quest05_four="this.P_Sec16_Quest05_Question04" :quest05_five="this.P_Sec16_Quest05_Question05" :quest05_six="this.P_Sec16_Quest05_Question06" />
      </div>
      <div v-if="checkbox_Q_Sec17_VideoLink">
       <prev_Q_Sec17_VideoLink :titleOne="this.Q_Sec17_VideoLink_Video_One_Title" :titleTwo="Q_Sec17_VideoLink_Video_Two_Title" />
      </div>
    </div>
    <div class="modal-footer">
      <div class="container">
         <div class="row">
            <a href="#!" class="modal-close btn-flat left" @click.prevent="sendLessonToDataBase">verzenden</a>
            <a href="#!" class="modal-close btn-flat" @click.prevent="">annuleer</a>
         </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from 'axios'

import lesId from "@/components/Create/Items/LesId.vue"
import A_Sec01_TitleLesson from "@/components/Create/Items/A_Sec01_TitleLesson.vue"
import Objectives from "@/components/Create/Items/Objectives.vue"
import B_Sec02_Par from "@/components/Create/Items/B_Sec02_Par.vue"
import C_Sec03_Quest01 from "@/components/Create/Items/C_Sec03_Quest01.vue"
import D_Sec04_ParImgL from "@/components/Create/Items/D_Sec04_ParImgL.vue"
import E_Sec05_Quest02 from "@/components/Create/Items/E_Sec05_Quest02.vue"
import F_Sec06_ParImgR from "@/components/Create/Items/F_Sec06_ParImgR.vue"
import G_Sec07_Par from "@/components/Create/Items/G_Sec07_Par.vue"
import H_Sec08_Quest03 from "@/components/Create/Items/H_Sec08_Quest03.vue"
import I_Sec09_Par from "@/components/Create/Items/I_Sec09_Par.vue"
import J_Sec10_Par from "@/components/Create/Items/J_Sec10_Par.vue"
import K_Sec11_ImgBig from "@/components/Create/Items/K_Sec11_ImgBig.vue"
import L_Sec12_Quest04 from "@/components/Create/Items/L_Sec12_Quest04.vue"
import M_Sec13_Par from "@/components/Create/Items/M_Sec13_Par.vue"
import N_Sec14_ParImgL from "@/components/Create/Items/N_Sec14_ParImgL.vue"
import O_Sec15_Par from "@/components/Create/Items/O_Sec15_Par.vue"
import P_Sec16_Quest05 from "@/components/Create/Items/P_Sec16_Quest05.vue"
import Q_Sec17_VideoLink from "@/components/Create/Items/Q_Sec17_VideoLink.vue"

import prev_A_Sec01_TitleLesson from "@/components/Create/Preview/Items/prev_A_Sec01_TitleLesson.vue"
import prev_Objectives from "@/components/Create/Preview/Items/prev_Objectives.vue"
import prev_B_Sec02_Par from "@/components/Create/Preview/Items/prev_B_Sec02_Par.vue"
import prev_C_Sec03_Quest01 from "@/components/Create/Preview/Items/prev_C_Sec03_Quest01.vue"
import prev_D_Sec04_ParImgL from "@/components/Create/Preview/Items/prev_D_Sec04_ParImgL.vue"
import prev_E_Sec05_Quest02 from "@/components/Create/Preview/Items/prev_E_Sec05_Quest02.vue"
import prev_F_Sec06_ParImgR from "@/components/Create/Preview/Items/prev_F_Sec06_ParImgR.vue"
import prev_G_Sec07_Par from "@/components/Create/Preview/Items/prev_G_Sec07_Par.vue"
import prev_H_Sec08_Quest03 from "@/components/Create/Preview/Items/prev_H_Sec08_Quest03.vue"
import prev_I_Sec09_Par from "@/components/Create/Preview/Items/prev_I_Sec09_Par.vue"
import prev_J_Sec10_Par from "@/components/Create/Preview/Items/prev_J_Sec10_Par.vue"
import prev_K_Sec11_ImgBig from "@/components/Create/Preview/Items/prev_K_Sec11_ImgBig.vue"
import prev_L_Sec12_Quest04 from "@/components/Create/Preview/Items/prev_L_Sec12_Quest04.vue"
import prev_M_Sec13_Par from "@/components/Create/Preview/Items/prev_M_Sec13_Par.vue"
import prev_N_Sec14_ParImgL from "@/components/Create/Preview/Items/prev_N_Sec14_ParImgL.vue"
import prev_O_Sec15_Par from "@/components/Create/Preview/Items/prev_O_Sec15_Par.vue"
import prev_P_Sec16_Quest05 from "@/components/Create/Preview/Items/prev_P_Sec16_Quest05.vue"
import prev_Q_Sec17_VideoLink from "@/components/Create/Preview/Items/prev_Q_Sec17_VideoLink.vue"

export default {
   components:{
      lesId,
      A_Sec01_TitleLesson,
      Objectives,
      B_Sec02_Par,
      C_Sec03_Quest01,
      D_Sec04_ParImgL,
      E_Sec05_Quest02,
      F_Sec06_ParImgR,
      G_Sec07_Par,
      H_Sec08_Quest03,
      I_Sec09_Par, 
      J_Sec10_Par,
      K_Sec11_ImgBig,
      L_Sec12_Quest04,
      M_Sec13_Par, 
      N_Sec14_ParImgL,
      O_Sec15_Par, 
      P_Sec16_Quest05,
      Q_Sec17_VideoLink,

      prev_A_Sec01_TitleLesson,
      prev_Objectives,
      prev_B_Sec02_Par,
      prev_C_Sec03_Quest01,
      prev_D_Sec04_ParImgL,
      prev_E_Sec05_Quest02,
      prev_F_Sec06_ParImgR,
      prev_G_Sec07_Par,
      prev_H_Sec08_Quest03,
      prev_I_Sec09_Par, 
      prev_J_Sec10_Par, 
      prev_K_Sec11_ImgBig,
      prev_L_Sec12_Quest04,
      prev_M_Sec13_Par, 
      prev_N_Sec14_ParImgL,
      prev_O_Sec15_Par,
      prev_P_Sec16_Quest05,
      prev_Q_Sec17_VideoLink 
   },
   data(){
         return {

            message: false,
            errorMessage: false,
            requestError: "",

            // checkboxes
            checkbox_B_Sec02_Par: false,
            checkbox_C_Sec03_Quest01: false,
            checkbox_D_Sec04_ParImgL: false,
            checkbox_E_Sec05_Quest02: false,
            checkbox_F_Sec06_ParImgR: false,
            checkbox_G_Sec07_Par: false,
            checkbox_H_Sec08_Quest03: false,
            checkbox_I_Sec09_Par: false,
            checkbox_J_Sec10_Par: false,
            checkbox_K_Sec11_ImgBig: false,
            checkbox_L_Sec12_Quest04: false,
            checkbox_M_Sec13_Par: false,
            checkbox_N_Sec14_ParImgL: false,
            checkbox_O_Sec15_Par: false,
            checkbox_P_Sec16_Quest05: false,
            checkbox_Q_Sec17_VideoLink: false,

            // data from props
            // educationform
            educationform: "",
            // lesID
            lesId: "",
            // title lesson
            A_Sec01_TitleLesson: "",
            // Objectives
            Objectives_Objective01: "",
            Objectives_Objective02: "",
            Objectives_Objective03: "",
            Objectives_Objective04: "",
            Objectives_Objective05: "",
            Objectives_Objective06: "",
            // B
            B_Sec02_Par_Title: "",
            B_Sec02_Par_Text: "",
            // C
            C_Sec03_Quest01_Question01: "",
            C_Sec03_Quest01_Question02: "",
            C_Sec03_Quest01_Question03: "",
            C_Sec03_Quest01_Question04: "",
            C_Sec03_Quest01_Question05: "",
            C_Sec03_Quest01_Question06: "",
            // D
            D_Sec04_ParImgL_Title: "",
            D_Sec04_ParImgL_Text: "",
            D_Sec04_ParImgL_Image_Text: "",
            D_Sec04_ParImgL_Image_URL: "",
            // E
            E_Sec05_Quest02_Question01: "",
            E_Sec05_Quest02_Question02: "",
            E_Sec05_Quest02_Question03: "",
            E_Sec05_Quest02_Question04: "",
            E_Sec05_Quest02_Question05: "",
            E_Sec05_Quest02_Question06: "",
            // F
            F_Sec06_ParImgR_Title: "",
            F_Sec06_ParImgR_Text: "",
            F_Sec06_ParImgR_Image_Text: "",
            F_Sec06_ParImgR_Image_URL: "",
            // G
            G_Sec07_Par_Title: "",
            G_Sec07_Par_Text: "",
            // H 
            H_Sec08_Quest03_Question01: "",
            H_Sec08_Quest03_Question02: "",
            H_Sec08_Quest03_Question03: "",
            H_Sec08_Quest03_Question04: "",
            H_Sec08_Quest03_Question05: "",
            H_Sec08_Quest03_Question06: "",
            // I
            I_Sec09_Par_Title: "",
            I_Sec09_Par_Text: "",
            // J
            J_Sec10_Par_Title: "",
            J_Sec10_Par_Text: "",
            // K
            K_Sec11_ImgBig_Image_Text: "",
            K_Sec11_ImgBig_Image_URL: "",
            // L
            L_Sec12_Quest04_Question01: "",
            L_Sec12_Quest04_Question02: "",
            L_Sec12_Quest04_Question03: "",
            L_Sec12_Quest04_Question04: "",
            L_Sec12_Quest04_Question05: "",
            L_Sec12_Quest04_Question06: "",
            // M
            M_Sec13_Par_Title: "",
            M_Sec13_Par_Text: "",
            // N
            N_Sec14_ParImgL_Title: "",
            N_Sec14_ParImgL_Text: "",
            N_Sec14_ParImgL_Image_Text: "",
            N_Sec14_ParImgL_Image_URL: "",
            // O
            O_Sec15_Par_Title: "",
            O_Sec15_Par_Text: "",
            // P
            P_Sec16_Quest05_Question01: "",
            P_Sec16_Quest05_Question02: "",
            P_Sec16_Quest05_Question03: "",
            P_Sec16_Quest05_Question04: "",
            P_Sec16_Quest05_Question05: "",
            P_Sec16_Quest05_Question06: "",
            // Q
            Q_Sec17_VideoLink_Video_One_Title: "",
            Q_Sec17_VideoLink_Video_One_Url: "",
            Q_Sec17_VideoLink_Video_Two_Title: "",
            Q_Sec17_VideoLink_Video_Two_Url: "",

            lessonObj:{
               educationform: "",
               lesId: "",
               A_Sec01_TitleLesson: "",
               Objectives: [],
               B_Sec02_Par:{
                  title: "",
                  text:""
               },
               C_Sec03_Quest01:{
                  one:"",
                  two:"",
                  three:"",
                  four:"",
                  five:"",
                  six:""
               },
               D_Sec04_ParImgL:{
                  imgUrl:"",
                  imgText:"",
                  title:"",
                  text:""
	            },
               E_Sec05_Quest02:{
                  one:"",
                  two:"",
                  three:"",
                  four:"",
                  five:"",
                  six:""
               },
               F_Sec06_ParImgR:{
                  imgUrl:"",
                  imgText:"",
                  title:"",
                  text:""
	            },
               G_Sec07_Par:{
                  title: "",
                  text:""
               },
               H_Sec08_Quest03:{
                  one:"",
                  two:"",
                  three:"",
                  four:"",
                  five:"",
                  six:""
               },
               I_Sec09_Par:{
                  title: "",
                  text:""
               },
               J_Sec10_Par:{
                  title: "",
                  text:""
               },
               K_Sec11_ImgBig:{
                  imgUrl:"",
                  imgText:""
	            },
               L_Sec12_Quest04:{
                  one:"",
                  two:"",
                  three:"",
                  four:"",
                  five:"",
                  six:""
               },
               M_Sec13_Par:{
                  title: "",
                  text:""
               },
               N_Sec14_ParImgL:{
                  imgUrl:"",
                  imgText:"",
                  title:"",
                  text:""
	            },
               O_Sec15_Par:{
                  title:"",
                  text:""
               },
               P_Sec16_Quest05:{
                  one:"",
                  two:"",
                  three:"",
                  four:"",
                  five:"",
                  six:""
               },
               Q_Sec17_VideoLink:{
		            videoTitleOne:"",
		            videoOneUrl:"",
                  videoTitleTwo:"",
		            videoTwoUrl:""
	            }
            }
      }
   },
   methods: {
      createLessonObj(){
         this.lessonObj.Objectives = []

         // educationform
         this.lessonObj.educationform = this.educationform
         // lesId
         this.lessonObj.lesId = this.lesId
         // A
         this.lessonObj.A_Sec01_TitleLesson = this.A_Sec01_TitleLesson
         // Objectives
         this.lessonObj.Objectives.push(
            this.Objectives_Objective01,
            this.Objectives_Objective02,
            this.Objectives_Objective03,
            this.Objectives_Objective04,
            this.Objectives_Objective05,
            this.Objectives_Objective06
         )
         // B
         this.lessonObj.B_Sec02_Par.title = this.B_Sec02_Par_Title
         this.lessonObj.B_Sec02_Par.text = this.B_Sec02_Par_Text
         // C
         this.lessonObj.C_Sec03_Quest01.one = this.C_Sec03_Quest01_Question01
         this.lessonObj.C_Sec03_Quest01.two = this.C_Sec03_Quest01_Question02
         this.lessonObj.C_Sec03_Quest01.three = this.C_Sec03_Quest01_Question03
         this.lessonObj.C_Sec03_Quest01.four = this.C_Sec03_Quest01_Question04
         this.lessonObj.C_Sec03_Quest01.five = this.C_Sec03_Quest01_Question05
         this.lessonObj.C_Sec03_Quest01.six = this.C_Sec03_Quest01_Question06
         // D
         this.lessonObj.D_Sec04_ParImgL.title = this.D_Sec04_ParImgL_Title
         this.lessonObj.D_Sec04_ParImgL.text = this.D_Sec04_ParImgL_Text
         this.lessonObj.D_Sec04_ParImgL.imgText = this.D_Sec04_ParImgL_Image_Text
         this.lessonObj.D_Sec04_ParImgL.imgUrl = this.D_Sec04_ParImgL_Image_URL
         // E
         this.lessonObj.E_Sec05_Quest02.one = this.E_Sec05_Quest02_Question01
         this.lessonObj.E_Sec05_Quest02.two = this.E_Sec05_Quest02_Question02
         this.lessonObj.E_Sec05_Quest02.three = this.E_Sec05_Quest02_Question03
         this.lessonObj.E_Sec05_Quest02.four = this.E_Sec05_Quest02_Question04
         this.lessonObj.E_Sec05_Quest02.five = this.E_Sec05_Quest02_Question05
         this.lessonObj.E_Sec05_Quest02.six = this.E_Sec05_Quest02_Question06
         // F
         this.lessonObj.F_Sec06_ParImgR.title = this.F_Sec06_ParImgR_Title
         this.lessonObj.F_Sec06_ParImgR.text = this.F_Sec06_ParImgR_Text
         this.lessonObj.F_Sec06_ParImgR.imgText = this.F_Sec06_ParImgR_Image_Text
         this.lessonObj.F_Sec06_ParImgR.imgUrl = this.F_Sec06_ParImgR_Image_URL
         // G
         this.lessonObj.G_Sec07_Par.title = this.G_Sec07_Par_Title
         this.lessonObj.G_Sec07_Par.text = this.G_Sec07_Par_Text
         // H
         this.lessonObj.H_Sec08_Quest03.one = this.H_Sec08_Quest03_Question01
         this.lessonObj.H_Sec08_Quest03.two = this.H_Sec08_Quest03_Question02
         this.lessonObj.H_Sec08_Quest03.three = this.H_Sec08_Quest03_Question03
         this.lessonObj.H_Sec08_Quest03.four = this.H_Sec08_Quest03_Question04
         this.lessonObj.H_Sec08_Quest03.five = this.H_Sec08_Quest03_Question05
         this.lessonObj.H_Sec08_Quest03.six = this.H_Sec08_Quest03_Question06
         // I
         this.lessonObj.I_Sec09_Par.title = this.I_Sec09_Par_Title
         this.lessonObj.I_Sec09_Par.text = this.I_Sec09_Par_Text
         // J
         this.lessonObj.J_Sec10_Par.title = this.J_Sec10_Par_Title
         this.lessonObj.J_Sec10_Par.text = this.J_Sec10_Par_Text
         // K
         this.lessonObj.K_Sec11_ImgBig.imgText = this.K_Sec11_ImgBig_Image_Text
         this.lessonObj.K_Sec11_ImgBig.imgUrl = this.K_Sec11_ImgBig_Image_URL
         // L
         this.lessonObj.L_Sec12_Quest04.one = this.L_Sec12_Quest04_Question01
         this.lessonObj.L_Sec12_Quest04.two = this.L_Sec12_Quest04_Question02
         this.lessonObj.L_Sec12_Quest04.three = this.L_Sec12_Quest04_Question03
         this.lessonObj.L_Sec12_Quest04.four = this.L_Sec12_Quest04_Question04
         this.lessonObj.L_Sec12_Quest04.five = this.L_Sec12_Quest04_Question05
         this.lessonObj.L_Sec12_Quest04.six = this.L_Sec12_Quest04_Question06
         // M
         this.lessonObj.M_Sec13_Par.title = this.M_Sec13_Par_Title
         this.lessonObj.M_Sec13_Par.text = this.M_Sec13_Par_Text
         // N
         this.lessonObj.N_Sec14_ParImgL.title = this.N_Sec14_ParImgL_Title
         this.lessonObj.N_Sec14_ParImgL.text = this.N_Sec14_ParImgL_Text
         this.lessonObj.N_Sec14_ParImgL.imgText = this.N_Sec14_ParImgL_Image_Text
         this.lessonObj.N_Sec14_ParImgL.imgUrl = this.N_Sec14_ParImgL_Image_URL
         // O
         this.lessonObj.O_Sec15_Par.title = this.O_Sec15_Par_Title
         this.lessonObj.O_Sec15_Par.text = this.O_Sec15_Par_Text
         // P
         this.lessonObj.P_Sec16_Quest05.one = this.P_Sec16_Quest05_Question01
         this.lessonObj.P_Sec16_Quest05.two = this.P_Sec16_Quest05_Question02
         this.lessonObj.P_Sec16_Quest05.three = this.P_Sec16_Quest05_Question03
         this.lessonObj.P_Sec16_Quest05.four = this.P_Sec16_Quest05_Question04
         this.lessonObj.P_Sec16_Quest05.five = this.P_Sec16_Quest05_Question05
         this.lessonObj.P_Sec16_Quest05.six = this.P_Sec16_Quest05_Question06
         // Q
         this.lessonObj.Q_Sec17_VideoLink.videoTitleOne = this.Q_Sec17_VideoLink_Video_One_Title
         this.lessonObj.Q_Sec17_VideoLink.videoOneUrl = this.Q_Sec17_VideoLink_Video_One_Url
         this.lessonObj.Q_Sec17_VideoLink.videoTitleTwo = this.Q_Sec17_VideoLink_Video_Two_Title
         this.lessonObj.Q_Sec17_VideoLink.videoTwoUrl = this.Q_Sec17_VideoLink_Video_Two_Url

         console.log(this.lessonObj)
      },
      async sendLessonToDataBase(){
        // create url for axios
        let url = `/api/v1/lessons`
        // make post request with axios with the lesson data
         try {
         let response = await axios.post(url, this.lessonObj)
            // show message ok
            this.message = true
            // wait 3 sec and let message ok disappear
            setTimeout(() => {
               this.message = false
            }, 3000);
         }
         // SOMETHING WEND WRONG
         catch(error){
               if (error.response) {
                  // show error message
                  this.errorMessage = true
                  // show what kind of error it is
                  this.requestError = error.response.data
                     // The request was made and the server responded with a status code
                     // that falls out of the range of 2xx
                     console.log("response.data", error.response.data)
                     console.log("response.status", error.response.status)
               }else if (error.request) {
                     // The request was made but no response was received
                     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                     // http.ClientRequest in node.js
                     console.log(error.request)
                     this.errorMessage = true
                     this.requestError = error.response.data
               } else {
               // Something happened in setting up the request that triggered an Error
               console.log('Error', error.message)
               this.errorMessage = true
               this.requestError = error.response.data
               }
            // wait 10 sec and let the error message disappear
            setTimeout(() => {
            this.errorMessage = false
            }, 10000);
         }
      }
   },
   mounted(){
      M.AutoInit()
  },
  watch:{
      checkbox_B_Sec02_Par(){
         if(!this.checkbox_B_Sec02_Par){
            this.B_Sec02_Par_Title = ""
            this.B_Sec02_Par_Text = ""
         }
   },
      checkbox_C_Sec03_Quest01(){
         if(!this.checkbox_C_Sec03_Quest01){
           	this.C_Sec03_Quest01_Question01 = ""
            this.C_Sec03_Quest01_Question02 = ""
            this.C_Sec03_Quest01_Question03 = ""
            this.C_Sec03_Quest01_Question04 = ""
            this.C_Sec03_Quest01_Question05 = ""
            this.C_Sec03_Quest01_Question06 = ""
         }
   },
      checkbox_D_Sec04_ParImgL(){
         if(!this.checkbox_D_Sec04_ParImgL){
            this.D_Sec04_ParImgL_Title = ""
            this.D_Sec04_ParImgL_Text = ""
            this.D_Sec04_ParImgL_Image_Text = ""
            this.D_Sec04_ParImgL_Image_URL = ""
         }
   },
      checkbox_E_Sec05_Quest02(){
         if(!checkbox_E_Sec05_Quest02){
            this.E_Sec05_Quest02_Question01 = ""
            this.E_Sec05_Quest02_Question02 = ""
            this.E_Sec05_Quest02_Question03 = ""
            this.E_Sec05_Quest02_Question04 = ""
            this.E_Sec05_Quest02_Question05 = ""
            this.E_Sec05_Quest02_Question06 = ""
         }
   },
      checkbox_F_Sec06_ParImgR(){
         if(!this.checkbox_F_Sec06_ParImgR){
            this.F_Sec06_ParImgR_Title = ""
            this.F_Sec06_ParImgR_Text = ""
            this.F_Sec06_ParImgR_Image_Text = ""
            this.F_Sec06_ParImgR_Image_URL = ""
         }
   },
      checkbox_G_Sec07_Par(){
         if(!this.checkbox_G_Sec07_Par){
            this.G_Sec07_Par_Title = ""
            this.G_Sec07_Par_Text = ""
         }
   },
      checkbox_H_Sec08_Quest03(){
         if(!this.checkbox_H_Sec08_Quest03){
            this.H_Sec08_Quest03_Question01 = ""
            this.H_Sec08_Quest03_Question02 = ""
            this.H_Sec08_Quest03_Question03 = ""
            this.H_Sec08_Quest03_Question04 = ""
            this.H_Sec08_Quest03_Question05 = ""
            this.H_Sec08_Quest03_Question06 = ""
         }
   },
      checkbox_I_Sec09_Par(){
         if(!this.checkbox_I_Sec09_Par){
            this.I_Sec09_Par_Title = ""
            this.I_Sec09_Par_Text = ""
         }
   },
      checkbox_J_Sec10_Par(){
         if(!this.checkbox_J_Sec10_Par){
            this.J_Sec10_Par_Title = ""
            this.J_Sec10_Par_Text = ""
         }
   },
      checkbox_K_Sec11_ImgBig(){
         if(!this.checkbox_K_Sec11_ImgBig){
            this.K_Sec11_ImgBig_Image_Text = ""
            this.K_Sec11_ImgBig_Image_URL = ""
         }
   },
      checkbox_L_Sec12_Quest04(){
         if(!this.checkbox_L_Sec12_Quest04){
            this.L_Sec12_Quest04_Question01 = ""
            this.L_Sec12_Quest04_Question02 = ""
            this.L_Sec12_Quest04_Question03 = ""
            this.L_Sec12_Quest04_Question04 = ""
            this.L_Sec12_Quest04_Question05 = ""
            this.L_Sec12_Quest04_Question06 = ""
         }
   },
      checkbox_M_Sec13_Par(){
         if(!this.checkbox_M_Sec13_Par){
            this.M_Sec13_Par_Title = ""
            this.M_Sec13_Par_Text = ""
         }
   },
      checkbox_N_Sec14_ParImgL(){
         if(!this.checkbox_N_Sec14_ParImgL){
            this.N_Sec14_ParImgL_Title = ""
            this.N_Sec14_ParImgL_Text = ""
            this.N_Sec14_ParImgL_Image_Text = ""
            this.N_Sec14_ParImgL_Image_URL = ""
         }
   },
      checkbox_O_Sec15_Par(){
         if(!this.checkbox_O_Sec15_Par){
            this.O_Sec15_Par_Title = ""
            this.O_Sec15_Par_Text = ""
         }
   },
      checkbox_P_Sec16_Quest05(){
         if(!this.checkbox_P_Sec16_Quest05){
            this.P_Sec16_Quest05_Question01 = ""
            this.P_Sec16_Quest05_Question02 = ""
            this.P_Sec16_Quest05_Question03 = ""
            this.P_Sec16_Quest05_Question04 = ""
            this.P_Sec16_Quest05_Question05 = ""
            this.P_Sec16_Quest05_Question06 = ""
         }
   },
      checkbox_Q_Sec17_VideoLink(){
         if(!this.checkbox_Q_Sec17_VideoLink){
            this.Q_Sec17_VideoLink_Video_One_Title = ""
            this.Q_Sec17_VideoLink_Video_One_Url = ""
            this.Q_Sec17_VideoLink_Video_Two_Title = ""
            this.Q_Sec17_VideoLink_Video_Two_Url = ""
         }
   }
  }
}
</script>

<style scoped>
.checkboxes{
  margin-top: 25px;
}
.modal { 
   width: 75% !important ; 
   height: 75% !important ; 
}
.message{
   margin-top: 300px;
}
.educationform{
     max-width: 500px;
  margin: 0 auto;
}
</style>