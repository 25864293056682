import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { plugin, defaultConfig } from '@formkit/vue'

import '@formkit/themes/genesis'

import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min'
// import 'material-design-icons/iconfont/material-icons.css'

import axios from 'axios'

// opslag token
require('@/store/subscriber')

// axios default URL
//axios.defaults.baseURL = 'http://localhost:5000'

//axios.defaults.baseURL = 'http://159.223.3.250'
axios.defaults.baseURL = 'http://188.166.72.12'

// re-authenticate trough local storage token
store.dispatch('auth/attempt', localStorage.getItem('token'))


createApp(App).use(store).use(router).use(plugin, defaultConfig).mount("#app");
