<template>
<section class="Q_Sec17_VideoLink">
   <div class="container">
      <div class="row">
         <div>
            <div class="col m6 s12">
               <div class="Q col s12 m8">
                  <div class="card padding">
                     <div class="row valign-wrapper">
                        <div class="col s12">
                           <p class="right tag">Q</p>
                           <h6><i>Links:</i></h6>
                           <ul >
                              <li><a  target="_blank" class="collection-item teal-text">Link 01</a></li>
                              <li><a  target="_blank" class="collection-item teal-text">Link 02</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col m6 s12 ">
               <div class="input-field col s12 inputFields" :value='Q_Sec17_VideoLink_Video_One_Title' @input='$emit("update:Q_Sec17_VideoLink_Video_One_Title", $event.target.value)'>
                  <input id="Q_Sec17_VideoLink_Video_One_Title" type="text" class="validate">
                  <label for="Q_Sec17_VideoLink_Video_One_Title"> Video one Title: </label>
               </div>
               <div class="input-field col s12 inputFields" :value='Q_Sec17_VideoLink_Video_One_Url' @input='$emit("update:Q_Sec17_VideoLink_Video_One_Url", $event.target.value)'>
                       <input id="Q_Sec17_VideoLink_Video_Two_Url" type="url" class="validate">
                  <label for="Q_Sec17_VideoLink_Video_Two_Url"> Video one URL: </label>
               </div>
               <div class="input-field col s12 inputFields" :value='Q_Sec17_VideoLink_Video_Two_Title' @input='$emit("update:Q_Sec17_VideoLink_Video_Two_Title", $event.target.value)'>
                  <input id="Q_Sec17_VideoLink_Video_Two_Title" type="text" class="validate">
                  <label for="Q_Sec17_VideoLink_Video_Two_Title"> Video two Title: </label>
               </div>
               <div class="input-field col s12 inputFields" :value='Q_Sec17_VideoLink_Video_Two_Url' @input='$emit("update:Q_Sec17_VideoLink_Video_Two_Url", $event.target.value)'>
                  <input id="Q_Sec17_VideoLink_Video_Two_Url" type="url" class="validate">
                  <label for="Q_Sec17_VideoLink_Video_Two_Url"> Video two URL: </label>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
    prop: {
        Q_Sec17_VideoLink_Video_One_Title: String,
        Q_Sec17_VideoLink_Video_One_Url: String,
        Q_Sec17_VideoLink_Video_Two_Title: String,
        Q_Sec17_VideoLink_Video_Two_Url: String
    },
    data(){
        return {
        Q_Sec17_VideoLink_Video_One_Title: "",
        Q_Sec17_VideoLink_Video_One_Url: "",
        Q_Sec17_VideoLink_Video_Two_Title: "",
        Q_Sec17_VideoLink_Video_Two_Url: ""
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
</style>