<template>
    <section class="A_Sec01_TitleLesson">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div class=" A col s12 m8 A_Sec01_TitleLesson ">
                        <div class="">
                            <div class="row">
                                <div class="col s12">
                                <div class="row">
                                    <div class="card" >
                                        <h6 class="card-content ">3_ASO_Lesblok_01_Les_01_Thema_1_Het begin van de Kerk </h6>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12 inputFields">
                    <div class="input-field col s12 " :value='A_Sec01_TitleLesson' @input='$emit("update:A_Sec01_TitleLesson", $event.target.value)'>
                        <input id="A_Sec01_TitleLesson" type="text" class="validate">
                        <label for="A_Sec01_TitleLesson">Title Lesson:</label>
                    </div>

                    
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
    A_Sec01_TitleLesson: String
    },
    data(){
        return {
        A_Sec01_TitleLesson:""
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -20px;
}
h6{
    font-size: 13px;
    margin-left: -5px;
}
.A_Sec01_TitleLesson{
    margin-top: -20px;
}
</style>