<template>
  <div v-if="lessonData.K_Sec11_ImgBig.imgUrl" class="K col s12 m8 offset-m2 l6 offset-l3">
    <div class="card">
      <div class="row valign-wrapper">
        <div class="col s12">
          <div class="center padding">
            <p class="right tag">K</p>
             <img v-bind:src=lessonData.K_Sec11_ImgBig.imgUrl alt="" class=" materialboxed responsive-img" draggable="false">  
          </div>
          <div class="card-content black-text center-align">
            <p class="image-text">{{ lessonData.K_Sec11_ImgBig.imgText }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {

       }
  },
  mounted(){

  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })  
  }
}
</script>

<style scoped>
/* .padding{ */
  /* padding-top: 50px; */
/* } */
.image-text{
font-style: italic;
}
.tag{
  color: rgb(255, 255, 255);
}
.K{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>