<template>
    <section class="Objectives">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div  class="C col s12 m8 ">
                        <div class="card padding">
                            <div class="row valign-wrapper">
                                <div class="col s12">
                                    <p class="right tag"></p>
                                    <h6 class="teal-text">doelstellingen:</h6>
                                    <ul >
                                        <li>doelstelling 01</li>
                                        <li>doelstelling 02</li>
                                        <li>doelstelling 03</li>
                                        <li>doelstelling 04</li>
                                        <li>doelstelling 05</li>
                                        <li>doelstelling 06</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12 test ">
                    <div class="input-field col s12 " :value='Objectives_Objective01' @input='$emit("update:Objectives_Objective01", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea "></textarea>
                        <label for="textarea1">doelstelling 01</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='Objectives_Objective02' @input='$emit("update:Objectives_Objective02", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea "></textarea>
                        <label for="textarea1">doelstelling 02</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='Objectives_Objective03' @input='$emit("update:Objectives_Objective03", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea "></textarea>
                        <label for="textarea1">doelstelling 03</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='Objectives_Objective04' @input='$emit("update:Objectives_Objective04", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea"></textarea>
                        <label for="textarea1">doelstelling 04</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='Objectives_Objective05' @input='$emit("update:Objectives_Objective05", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea"></textarea>
                        <label for="textarea1">doelstelling 05</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='Objectives_Objective06' @input='$emit("update:Objectives_Objective06", $event.target.value)'>
                        <textarea id="textarea1" class="materialize-textarea"></textarea>
                        <label for="textarea1">doelstelling 06</label>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
            Objectives_Objective01: String,
            Objectives_Objective02: String,
            Objectives_Objective03: String,
            Objectives_Objective04: String,
            Objectives_Objective05: String,
            Objectives_Objective06: String,
    },
    data(){
        return {
            Objectives_Objective01: "",
            Objectives_Objective02: "",
            Objectives_Objective03: "",
            Objectives_Objective04: "",
            Objectives_Objective05: "",
            Objectives_Objective06: "",
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
h6{
    font-size: 13px;
    margin-left: -5px;
}
.test{
    margin-top: -80px;
}
</style>