<template>
  <div>
    <teacherMenuComponent/>
  </div>
</template>

<script>
import teacherMenuComponent from "@/components/TeacherMenu/teacherMenu.vue"

export default {
    name: 'teacherMenu',
    components:{
      teacherMenuComponent
    }
}
</script>

<style>

</style>