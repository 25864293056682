<template>
  <div class="M col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
          <p class="right tag">M</p>
          <h6>{{ titleParagraph }}</h6>
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
 props: ['titleParagraph', 'text']
}
</script>

<style  scoped>
.padding{
  padding: 25px;
}
h6{
  font-weight: bold;
}
.tag{
  color: rgb(0, 0, 0);
}
.M{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>