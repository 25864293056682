<template>
    <section class="K_Sec11_ImgBig">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div class="K col s12 m8 ">
                        <div class="card">
                            <div class="row valign-wrapper">
                                <div class="col s12">
                                <div class="center padding">
                                    <p class="right tag">K</p>
                                    <img src="~@/assets/background.jpg" alt="" class=" materialboxed responsive-img">  
                                </div>
                                <div class="card-content black-text center-align">
                                    <p class="image-text">Image text</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12 inputFields">
                    <div class="input-field col s12 inputFields" :value='K_Sec11_ImgBig_Image_Text' @input='$emit("update:K_Sec11_ImgBig_Image_Text", $event.target.value)'>
                        <input id="K_Sec11_ImgBig_Image_Text" type="text" class="validate">
                        <label for="K_Sec11_ImgBig_Image_Text"> Image Text: </label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='K_Sec11_ImgBig_Image_URL' @input='$emit("update:K_Sec11_ImgBig_Image_URL", $event.target.value)'>
                        <input id="K_Sec11_ImgBig_Image_URL" type="url" class="validate">
                        <label for="K_Sec11_ImgBig_Image_URL"> Image URL: </label>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
        K_Sec11_ImgBig_Image_Text: String,
        K_Sec11_ImgBig_Image_URL: String,
    },
    data(){
        return {
            K_Sec11_ImgBig_Image_Text: "",
            K_Sec11_ImgBig_Image_URL: "",
        }
    }
}
</script>

<style>

</style>