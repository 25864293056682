<template>
  <div class="P col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
          <p class="right tag">P</p>
          <h6 class="teal-text"><i>Opdracht:</i></h6> 
          <ul >
            <li>{{ quest05_one }}</li>
            <li>{{ quest05_two }}</li>
            <li>{{ quest05_three }}</li>
            <li>{{ quest05_four }}</li>
            <li>{{ quest05_five }}</li>
            <li>{{ quest05_six }}</li>
          </ul>
          <div class="row">
            <form class="col s12">
              <div class="row">
                <div class="input-field col s12">
                  <textarea id="textarea1" class="materialize-textarea"></textarea>
                  <label for="textarea1">(Noteer voor elk antwoord het nummer van de vraag)</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: ['quest05_one', 'quest05_two', 'quest05_three', 'quest05_four', 'quest05_five', 'quest05_six']
}
</script>

<style  scoped>
.padding{
  padding: 25px;
}
.tag{
  color: rgb(0, 0, 0);
}
.P{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>