<template>
  <div v-if="lessonData.L_Sec12_Quest04.one" class="L col s12 m8 offset-m2 l6 offset-l3">
    <div class="card padding">
      <div class="row valign-wrapper">
        <div class="col s12">
          <p class="right tag">L</p>
          <h6 class="teal-text"><i>Opdracht:</i></h6> 
          <ul >
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.one }}</li>
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.two }}</li>
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.three }}</li>
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.four }}</li>
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.five }}</li>
            <li class="no-select">{{ lessonData.L_Sec12_Quest04.six }}</li>
          </ul>
          <div class="row">
            <form class="col s12">
              <div class="row">
                <div class="input-field col s12" :value='answersQuest04' @input='$emit("update:answersQuest04", $event.target.value)'>
                  <textarea id="textarea_L" class="materialize-textarea" ref="input_L" @paste="preventPaste" v-on:drop.prevent="preventDrop" v-on:dragover.prevent="preventDragOver"></textarea>
                  <label for="textarea_L">(Noteer voor elk antwoord het nummer van de vraag)</label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  prop: {
    answersQuest04: String
  },
 data(){
       return {
        answersQuest04:""
       }
  },
  mounted(){

  },
  methods: {
    preventPaste(event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData('text/plain');
      // Je kunt hier verdere validatie of verwerking uitvoeren indien nodig.
    },
    preventDrop(event) {
      event.preventDefault();
      event.stopPropagation()
    },
    preventDragOver(event) {
      event.preventDefault();
      event.stopPropagation()
    }
  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })
  }
}
</script>

<style  scoped>
.padding{
  padding: 25px;
}
.tag{
  color: rgb(255, 255, 255);
}
.L{
  max-width: 1000px;
  margin: 0 auto;
} 
.no-select {
  user-select: none;
}
</style>