<template>
  <div class="N col s12 m8 offset-m2 l6 offset-l3 ">
    <div class="card padding ">
      <div class="row ">
        <div class="col s3">
          <div class="card">
            <img v-bind:src=imageURL alt="" class=" materialboxed responsive-img"> 
            </div>
            <div class="card-content black-text center">
            <p class="image-text">{{ imageText }}</p>
            </div>
        </div>
        <div class="col s9">
          <p class="right tag">N</p>
          <h6 class="text-left">{{ titleParagraph }}</h6>
          <p>{{ text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  props: ['titleParagraph', 'text', 'imageText', 'imageURL']
} 
</script>

<style scoped>
.padding{
  padding: 25px;
}
.image-text{
font-style: italic;
}
.img{
  padding-top: 50px;
}
h6{
  font-weight: bold;
}
.tag{
  color: rgb(0, 0, 0);
}
.N{
  max-width: 1000px;
  margin: 0 auto;
} 
</style>