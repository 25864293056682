import axios from 'axios'


export default{
  namespaced: true,

  state: {
    //apiUrl: 'http://159.223.3.250/api/v1/users/',
    
    apiUrl: 'http://188.166.72.12/api/v1/users/',
    
    deleteResponse: null,
    deleteError: null,
    createResponse: null,
    createError: null,
    updateResponse: null,
    updateError: null
  },
  getters: {

  },
  mutations: {
    SET_DELETE_RESPONSE(state, response) {
      state.deleteResponse = response;
    },
    SET_DELETE_ERROR(state, error) {
      state.deleteError = error;
    },
    SET_CREATE_RESPONSE(state, response) {
      state.createResponse = response;
    },
    SET_CREATE_ERROR(state, error) {
      state.createError = error;
    },
    SET_UPDATE_RESPONSE(state, response){
      state.updateResponse = response;
    },
    SET_UPDATE_ERROR(state, error){
      state.updateError = error;
    }


  },
  actions: {

    // function to change educationForm 
    async switchCurriculum( _, newCurParams){
      // create url for request based on user input newCurParams from component "switchCurriculum"
      let url = '/api/v1/users/' + newCurParams.user
      // create var to collect value from newCurParams
      let educationForm = newCurParams.educationForm
      // create var object to collect value from newCurParams
      let payload = {"educationForm": educationForm }
      // make the request
      let response = await axios.put(url, payload)
      console.log(response.data)
    },

    async deleteUser({ commit, state }, userId) {
      try {
        // Voer het DELETE-verzoek uit om de gebruiker te verwijderen
        await axios.delete(`${state.apiUrl}/${userId}`);
    
        // Als het verwijderen succesvol is, commit de bijbehorende mutation
        commit('SET_DELETE_RESPONSE', userId);
      } catch (error) {
        console.error('Er is een fout opgetreden bij het verwijderen van een gebruiker:', error);
        // Als er een fout optreedt, commit de fout om deze af te handelen in de component
        commit('SET_DELETE_ERROR', error);
      }
    },


    async createUser({ commit, state }, newUserData) {
      try {
        // Hier gebruiken we Axios om een PUT-verzoek naar de server te sturen
        const response = await axios.post(`${state.apiUrl}/`, newUserData);
  
        // Als het verzoek succesvol is, bijwerken we de gebruiker in de store
        commit('SET_CREATE_RESPONSE', response.data);
  
      } catch (error) {
        // Handel fouten hier af
        console.error('Er is een fout opgetreden bij het creeeren van een nieuwe gebruiker:', error);
        // Als er een fout optreedt, commit de fout om deze af te handelen in de component
        commit('SET_CREATE_ERROR', error);
      }
    },


    async updateUser({ commit, state }, userId, testObj ) {
     
    try {

       //Dummygegevens voor de PUT-request
const dummyUserData = {
  school: "zonnebloem"
 
};


        console.log(`User ID: ${userId}`)
        console.log("testObj", testObj)

        // Hier gebruiken we Axios om een PUT-verzoek naar de server te sturen
        const response = await axios.put(`${state.apiUrl}/${userId}`, testObj );
  
        // Als het verzoek succesvol is, bijwerken we de gebruiker in de store
        commit('SET_UPDATE_RESPONSE', response.data);

        return response.data

      } catch (error) {
        // Handel fouten hier af
        console.error('Er is een fout opgetreden bij het updaten van een gebruiker:', error);
        // Als er een fout optreedt, commit de fout om deze af te handelen in de component
        commit('SET_UPDATE_ERROR', error);
      }
    }

  }

}



 