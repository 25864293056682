<template>
<div class="container test">
   <div class="card">
      <div class="container ">
         <div class="row ">
            <div class="card-content ">
               <div class="card card-background">
                  <h5 class="center">Leerlingen Beheer</h5>
                  <div class="col m6 s12 ">
                     <h6 class="textTitle">leerlingengegevens en evaluatie:</h6>
                  </div>
               </div>
               <div class="col m6 s12">
                  <div class="input-field col s12 ">
                     <div>
                        <div class="row">
                           <a href="#!" class="btn right " @click.prevent="evaluateAnswers">Bevestig </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</template>

<script>
export default {
methods:{
    evaluateAnswers(){
        this.$router.replace({ name: 'evaluate'})
        }
    }
}
</script>

<style scoped>
.test{
  margin-top: 50px;
}
.textTitle{
  margin-top: 30px;
}
.card-background{
  background: #f2f2f2;
}
</style>