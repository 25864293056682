
<template>

<div class="container">
  <h1>Gebruikersbeheer</h1>

  <!-- Create -->
  <div class="row">
    <div class="input-field col s6">
      <input v-model="newUser.name" type="text" id="newUserName">
      <label for="newUserName">Naam</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.email" type="text" id="newUserEmail">
      <label for="newUserEmail">E-mail</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.password" type="password" id="newUserPassword">
      <label for="newUserPassword">Wachtwoord</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.role" type="text" id="newUserRole">
      <label for="newUserRole">Rol</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.school" type="text" id="newUserSchool">
      <label for="newUserSchool">School</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.schoolClass" type="text" id="newUserSchoolClass">
      <label for="newUserSchoolClass">Klas</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.educationForm" type="text" id="newUserEducationForm">
      <label for="newUserEducationForm">Opleidingsvorm</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.pegoStartTime" type="number" id="newUserPegoStartTime">
      <label for="newUserPegoStartTime">Pego Starttijd</label>
    </div>
    <div class="input-field col s6">
      <input v-model="newUser.pegoEndTime" type="number" id="newUserPegoEndTime">
      <label for="newUserPegoEndTime">Pego Eindtijd</label>
    </div>
    <button @click="addUser" class="waves-effect waves-light btn">Toevoegen</button>
  </div>

  <!-- Read -->
  <ul class="collection">
    <li v-for="user in users" :key="user._id" class="collection-item">
      <span>{{ user.name }}</span>
      <a @click="editUser(user)" href="#!" class="secondary-content"><i class="material-icons">edit</i></a>
      <a @click="deleteUser(user._id)" href="#!" class="secondary-content"><i class="material-icons">delete</i></a>
    </li>
  </ul>

  <!-- Update -->
  <div v-if="selectedUser">
    <h5>Bewerk gebruiker</h5>
    <div class="row">
      <div class="input-field col s6">
        <input v-model="selectedUser.name" type="text" id="editUserName">
        <label for="editUserName">Naam bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.email" type="text" id="editUserEmail">
        <label for="editUserEmail">E-mail bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.password" type="password" id="editUserPassword">
        <label for="editUserPassword">Wachtwoord bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.role" type="text" id="editUserRole">
        <label for="editUserRole">Rol bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.school" type="text" id="editUserSchool">
        <label for="editUserSchool">School bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.schoolClass" type="text" id="editUserSchoolClass">
        <label for="editUserSchoolClass">Klas bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.educationForm" type="text" id="editUserEducationForm">
        <label for="editUserEducationForm">Opleidingsvorm bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.pegoStartTime" type="number" id="editUserPegoStartTime">
        <label for="editUserPegoStartTime">Pego Starttijd bewerken</label>
      </div>
      <div class="input-field col s6">
        <input v-model="selectedUser.pegoEndTime" type="number" id="editUserPegoEndTime">
        <label for="editUserPegoEndTime">Pego Eindtijd bewerken</label>
      </div>
      <button @click="updateUser" class="waves-effect waves-light btn">Bijwerken</button>
    </div>
  </div>
</div>










    
</template>

<script>
export default {

data() {
    return {
      users: [
        { id: 1, name: 'Gebruiker 1' },
        { id: 2, name: 'Gebruiker 2' },
        // Voeg hier meer gebruikers toe als dat nodig is
      ],
      newUser: {
        name: ''
      },
      selectedUser: null
    };
  },
  methods: {
    addUser() {
      this.users.push({
        id: this.users.length + 1,
        name: this.newUser.name
      });
      this.newUser.name = '';
      M.toast({ html: 'Gebruiker toegevoegd!' });
    },
    editUser(user) {
      this.selectedUser = user;
    },
    updateUser() {
      this.selectedUser = null;
      M.toast({ html: 'Gebruiker bijgewerkt!' });
    },
    deleteUser(userId) {
      this.users = this.users.filter(user => user.id !== userId);
      M.toast({ html: 'Gebruiker verwijderd!' });
    }
  }

}
</script>