<template>
       <div v-if="lessonData.Objectives.length > 0" class=" A col s12 m8 offset-m2 l6 offset-l3">
        <div class="">
          <div class="row">
            <div class="col s12">
              <div class="row ">
                <div class="card" >
                  <h6 >Doelstellingen:</h6>
                  <ul >
                     <li class="objective" v-for="item in lessonData.Objectives" :key="item.index">
                      <p >{{item}}</p>
                     </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
 data(){
       return {

  }
 },
  mounted(){

  },
  methods:{

  },
  computed: {
    ...mapGetters({
      lessonData: 'lesson/lessonData'
    })
  }
}
</script>


<style scoped>
h6{
padding: 10px;
}
.A{
  max-width: 1000px;
  margin: 0 auto;
  margin-top: -20px;
 
 
} 
.objective{
 margin-left: 50px;
 margin-top: -10px;
}

</style>