<template>
  <div class="home-background">
    <div class="primary-overlay">
      <div class="showcase container ">
        <div class="row center-signIn">
          <div class="col s12 main-text ">
            <div class="welcome container  ">
              <div class="card center-signIn-card ">
                <div class="card-content center-align">
                  <h4 class="blue-grey-text">Welkom</h4>
                  <br>
                  <p v-if="feedback" class="red-text">{{ feedback }}</p>
                  <form @submit.prevent="submit">
                    <div class="input-field">
                      <label for="email">Email</label>
                      <input  type="text" name="email" id="email" v-model="form.email" @keypress="clearFeedback">
                    </div>
                    <br>
                    <div class="input-field">
                      <label for="password">Password</label>
                      <input type="password" name="password" id="password" v-model="form.password" @keypress="clearFeedback">
                    </div>
                    <br>
                    <button type="submit" class="btn blue-grey darken-3">Enter</button> 
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: 'signin',
    components:{

    },
    data() {
      return{
          form: {
            email: "",
            password: ""
          },
          feedback: null
      }
  },
    methods: {
      ...mapActions({
        signIn: 'auth/signIn'
      }),
     submit(){
        this.signIn(this.form).then(() => {
          this.$router.replace({
            name: 'curriculum'
          })
        }).catch(() => {
          console.log('Sign in failed')
          this.feedback = "Invalid Email or password."
        })
      },
      clearFeedback(){
        if(this.feedback){
          this.feedback = null
        }
      }
    }
}
</script>

<style scoped>
.home-background {
  background: url('~@/assets/background.jpg');
  background-size: cover;
  background-position: center;
  height: calc(100vh - 64px);
  min-height: 800px;
  color: #fff;
  position: relative;
}
.primary-overlay {
  background: rgba(63, 57, 77, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.card{
margin-top: 150px;
width:400px;
}
.center-signIn-card {
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* label underline focus color */ 
.input-field input:focus { 
 border-bottom: 1px solid #37474f !important; 
 box-shadow: 0 1px 0 0 #37474f !important; 
} 

</style>