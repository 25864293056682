import axios from 'axios'

export default{
  namespaced: true,

  state: {
    lessonIdFromAnswers: "",
    lessonTitleAndAnswerIdFromAnswers: "",
    singleAnswer:"",
    singleAnswerlessonTitle: "",
    //apiUrl: 'http://159.223.3.250/api/v1/answers/',
    apiUrl: 'http://188.166.72.12/api/v1/answers/',
    deleteResponses: null,
    deleteError: null,
    deleteSingleAnswerResponse: null,
    deleteSingleAnswerError: null


  },
  getters: {
    // getter answer component
    lessonTitleAndAnswerIdFromAnswers(state){
      return state.lessonTitleAndAnswerIdFromAnswers
    },
    // getter answer component
    singleAnswer(state){
      console.log("getter shit", state.singleAnswer)
      return state.singleAnswer
    },
    // getter curriculum component
    lessonIdFromAnswers(state){
      return state.lessonIdFromAnswers
    },
    singleAnswerlessonTitle(state){
      return state.singleAnswerlessonTitle
    }
  },
  mutations: {
    SET_LESSONTITLE_AND_ANSWER_ID_FROM_ANSWERS(state, lessonTitleAndAnswerIdFromAnswers){
      state.lessonTitleAndAnswerIdFromAnswers = lessonTitleAndAnswerIdFromAnswers
    },
    SET_LESSON_ID_FROM_ANSWERS(state, lessonIdFromAnswers){
      state.lessonIdFromAnswers = lessonIdFromAnswers
    },
    SET_SINGLE_ANSWER(state, singleAnswer){
      state.singleAnswer = singleAnswer
    },
    SET_SINGLE_ANSWER_LESSONTITLE(state, lessonTitle){
      state.singleAnswerlessonTitle = lessonTitle
    },
    RESET_LESSONTITLE_AND_ANSWERID_FROM_ANSWERS(state) {
      state.lessonTitleAndAnswerIdFromAnswers = ""
    },
    SET_DELETE_RESPONSES(state, responses) {
      state.deleteResponses = responses;
    },
    SET_DELETE_ERROR(state, error) {
      state.deleteError = error;
    },
    SET_DELETE_SINGLE_ANSWER_RESPONSE(state, response) {
      state.deleteSingleAnswerResponse= response;
    },
    SET_DELETE_SINGLE_ANSWER_ERROR(state, error) {
      state.deleteSingleAnswerError = error;
    },
    




    // RESET_LESSON_ID_FROM_ANSWERS(state, answersId){
    //   state.answersId = answersId
    // }
  },
  actions: {
      // async getAllAnswersFromUser( { commit }, userId){
      //   let response = await axios.get('/api/v1/answers?user=' + userId + '&sort=lesId')
      //     let lessonIdFromAnswers = []
      //     let lessonTitleAndAnswerIdFromAnswers =  []
      //     for (var i = 0, l = response.data.data.length; i < l; i++) {
      //       var lessonId = response.data.data[i].lesson._id
      //       var lessonTitle = response.data.data[i].lesson.A_Sec01_TitleLesson
      //       var answerId = response.data.data[i]._id
      //       lessonIdFromAnswers.push(lessonId)
      //       lessonTitleAndAnswerIdFromAnswers.push({lessonTitle, answerId})
      //     }
      //     try {
      //       commit('SET_LESSON_ID_FROM_ANSWERS', lessonIdFromAnswers)
      //       commit('SET_LESSONTITLE_AND_ANSWER_ID_FROM_ANSWERS', lessonTitleAndAnswerIdFromAnswers)
      //     } 
      //     catch (error) {
      //       console.log('Error: Er werden geen antwoorden gevonden')
      //       commit('RESET_LESSON_ID_FROM_ANSWERS', null)
      //     }
      // },



      // async getAllAnswersFromUser({ commit }, userId) {
      //   try {
      //     // Eerst een HTTP-verzoek naar '/api/v1/answers' doen
      //     const answersResponse = await axios.get('/api/v1/answers?user=' + userId + '&sort=lesId');
          
      //     let lessonIdFromAnswers = [];
      //     let lessonTitleAndAnswerIdFromAnswers = [];
      //     let test = [];
          
      //     for (let i = 0; i < answersResponse.data.data.length; i++) {
      //       const lessonId = answersResponse.data.data[i].lesson._id;
      //       const lessonTitle = answersResponse.data.data[i].lesson.A_Sec01_TitleLesson;
      //       const answerId = answersResponse.data.data[i]._id;
            
            
      //       try {
      //         const response = await axios.get(`/api/v1/answers/${answerId}/reviews`);
        
      //         // Controleer of de respons leeg is
      //         if (response.data.data[0]) {
      //           //test.push(response.data.data[0]);
      //           const review = response.data.data[0]
      //           console.log("kutz0oi", response.data.data[0])
      //         } else {
      //           console.log(`voor de antwoorden met ID ${answerId} bestaat geen review of heeft lege gegevens.`);
      //         }
      //       } catch (error) {
      //         console.error(`Fout bij het ophalen van gebruikersgegevens voor ID ${answerId}:`, error);
      //       }
            
          
            
      //       lessonIdFromAnswers.push(lessonId);
      //       lessonTitleAndAnswerIdFromAnswers.push({ lessonTitle, answerId, review});
      //       //test.push({ review })
      //     }

      //     console.log("test", test)

      //     console.log("lessonTitleAndAnswerIdFromAnswers", lessonTitleAndAnswerIdFromAnswers )
          
      //     // Nu een HTTP-verzoek naar '/api/v1/reviews' doen
      //     //const reviewsResponse = await axios.get('/api/v1/reviews'); // Je moet mogelijk de URL aanpassen aan je eigen behoeften.
          
      //     // Hier kun je de gegevens uit reviewsResponse verwerken zoals je wilt
      //     //console.log("reviews", reviewsResponse.data.data )
          
      //     commit('SET_LESSON_ID_FROM_ANSWERS', lessonIdFromAnswers);
      //     commit('SET_LESSONTITLE_AND_ANSWER_ID_FROM_ANSWERS', lessonTitleAndAnswerIdFromAnswers);
      //   } catch (error) {
      //     console.log('Error: Er werden geen antwoorden of recensies gevonden');
      //     //commit('RESET_LESSON_ID_FROM_ANSWERS', null);
      //   }
      // },

      // HIER STAAN DE REVIEWS OOK IN !!!
      
      // async getAllAnswersFromUser({ commit }, userId) {
      //   try {
      //     const answersResponse = await axios.get('/api/v1/answers?user=' + userId + '&sort=lesId');
          
      //     const lessonIdFromAnswers = [];
      //     const lessonTitleAndAnswerIdFromAnswers = [];
      
      //     for (let i = 0; i < answersResponse.data.data.length; i++) {
      //       const lessonId = answersResponse.data.data[i].lesson._id;
      //       const lessonTitle = answersResponse.data.data[i].lesson.A_Sec01_TitleLesson;
      //       const answerId = answersResponse.data.data[i]._id;
      
      //       let review = null; // Standaardwaarde voor review
      
      //       try {
      //         const response = await axios.get(`/api/v1/answers/${answerId}/reviews`);
        
      //         if (response.data.data[0]) {
      //           review = response.data.data[0];
      //         } else {
      //           console.log(`Voor de antwoorden met ID ${answerId} bestaat geen review of heeft lege gegevens.`);
      //         }
      //       } catch (error) {
      //         console.error(`Fout bij het ophalen van recensies voor ID ${answerId}:`, error);
      //       }
      
      //       lessonIdFromAnswers.push(lessonId);
      //       lessonTitleAndAnswerIdFromAnswers.push({ lessonTitle, answerId, review });
      //     }
      
      //     // Hier kun je de 'test' array bijwerken als dat nodig is voor debugging
      //     // console.log("test", test);
      
      //     // Hier kun je de 'lessonTitleAndAnswerIdFromAnswers' array loggen voor debugdoeleinden
      //      console.log("lessonTitleAndAnswerIdFromAnswers", lessonTitleAndAnswerIdFromAnswers.review)
      
      //     commit('SET_LESSON_ID_FROM_ANSWERS', lessonIdFromAnswers);
      //     commit('SET_LESSONTITLE_AND_ANSWER_ID_FROM_ANSWERS', lessonTitleAndAnswerIdFromAnswers);
      //   } catch (error) {
      //     console.log('Error: Er werden geen antwoorden of recensies gevonden');
      //     // commit('RESET_LESSON_ID_FROM_ANSWERS', null);
      //   }
      // },


      async getAllAnswersFromUser({ commit }, userId) {
        try {
          const answersResponse = await axios.get('/api/v1/answers?user=' + userId + '&sort=lesId');
          
          console.log("getAllAnswersFromUser method from answer-store: ", answersResponse )
          const lessonIdFromAnswers = [];
          const lessonTitleAndAnswerIdFromAnswers = [];
      
          for (let i = 0; i < answersResponse.data.data.length; i++) {
            const lessonId = answersResponse.data.data[i].lesson._id;
            const lessonTitle = answersResponse.data.data[i].lesson.A_Sec01_TitleLesson;
            const answerId = answersResponse.data.data[i]._id;
      
            let review = null; // Standaardwaarde voor review
      
            try {
              const response = await axios.get(`/api/v1/answers/${answerId}/reviews`);
      
              if (response.data.data[0]) {
                review = response.data.data[0];
              } else {
                console.log(`Voor de antwoorden met ID ${answerId} bestaat geen review of heeft lege gegevens.`);
              }
            } catch (error) {
              console.error(`Fout bij het ophalen van recensies voor ID ${answerId}:`, error);
            }
      
            lessonIdFromAnswers.push(lessonId);
            lessonTitleAndAnswerIdFromAnswers.push({ lessonTitle, answerId, review });
          }
      
          // Hier kun je de 'test' array bijwerken als dat nodig is voor debugging
          // console.log("test", test);
      
          // Hier kun je de 'lessonTitleAndAnswerIdFromAnswers' array loggen voor debugdoeleinden
          console.log("lessonTitleAndAnswerIdFromAnswers", lessonTitleAndAnswerIdFromAnswers);
      
          for (let i = 0; i < lessonTitleAndAnswerIdFromAnswers.length; i++) {
            const item = lessonTitleAndAnswerIdFromAnswers[i];
            if (item.review) {
              console.log(`Rating voor item ${i}: ${item.review.rating}`);
            } else {
              console.log(`Er is geen review beschikbaar voor item ${i}`);
            }
          }
      
          commit('SET_LESSON_ID_FROM_ANSWERS', lessonIdFromAnswers);
          commit('SET_LESSONTITLE_AND_ANSWER_ID_FROM_ANSWERS', lessonTitleAndAnswerIdFromAnswers);
        } catch (error) {
          console.log('Error: Er werden geen antwoorden of recensies gevonden');
          // commit('RESET_LESSON_ID_FROM_ANSWERS', null);
        }
      },
      
      async getSingleAnswer( { commit }, answerId){
        let response = await axios.get('/api/v1/answers/'+ answerId)
          let singleAnswer = []
          var lessonTitle = response.data.data.lesson.A_Sec01_TitleLesson
          //console.log(lessonTitle )
          for (var i = 0, l = response.data.data.questions.length; i < l; i++) {
            var question = response.data.data.questions[i]
            var answer = response.data.data.answers[i]  
            singleAnswer.push({question, answer})
          }
         
          //singleAnswer.push({lessonTitle})
          console.log('singleAnswer', singleAnswer)
          try {
            commit('SET_SINGLE_ANSWER', singleAnswer)
            commit('SET_SINGLE_ANSWER_LESSONTITLE', lessonTitle)
          } 
          catch (error) {
            console.log('Error: Er werden geen antwoorden gevonden')
          }
        
      },
      async deleteAllAnswersFromUser({ commit, state }, recordIdsToDelete) {
        try {
          const deleteRequests = recordIdsToDelete.map(recordId => {
            return axios.delete(`${state.apiUrl}/${recordId}`);
          });
    
          const responses = await Promise.all(deleteRequests);
          
          commit('SET_DELETE_RESPONSES', responses);
        } catch (error) {
          commit('SET_DELETE_ERROR', error);
        }
      },

      async deleteSingleAnswer({ commit, state }, answerId) {
        try {
          // Voer het DELETE-verzoek uit om de gebruiker te verwijderen
          await axios.delete(`${state.apiUrl}/${answerId}`);
      
          // Als het verwijderen succesvol is, commit de bijbehorende mutation
          commit('SET_DELETE_SINGLE_ANSWER_RESPONSE', answerId);
        } catch (error) {
          console.error('Er is een fout opgetreden bij het verwijderen van een gebruiker:', error);
          // Als er een fout optreedt, commit de fout om deze af te handelen in de component
          commit('SET_DELETE_SINGLE_ANSWER_ERROR', error);
        }
      },
      
  }
}
