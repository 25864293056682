<template>
  <div>
    <curriculumComponent/>
  </div>
</template>

<script>
import curriculumComponent from "@/components/Curriculum/curriculum.vue"

export default {
    name: 'curriculum',
    components:{
      curriculumComponent
    }
}
</script>

<style>

</style>