import axios from 'axios'

export default{
  namespaced: true,

    state: {
      lesId : "",
      _id: "",
      A_Sec01_TitleLesson : "",
      Objectives:[],
      B_Sec02_Par : "",
      C_Sec03_Quest01 : "",
      D_Sec04_ParImgL : "",
      E_Sec05_Quest02 : "",
      F_Sec06_ParImgR : "",
      G_Sec07_Par : "",
      H_Sec08_Quest03 : "",
      I_Sec09_Par : "",
      J_Sec10_Par : "",
      K_Sec11_ImgBig : "",
      L_Sec12_Quest04 : "",
      M_Sec13_Par : "",
      N_Sec14_ParImgL : "",
      O_Sec15_Par : "",
      P_Sec16_Quest05 : "",
      Q_Sec17_VideoLink : ""
    },
    getters: {
      lessonData (state){
        return state
      }
    },
    mutations: {
      SET_LESSONDATA (state, lesson){
        state.lesId = lesson.lesId
        state._id = lesson._id
        state.A_Sec01_TitleLesson = lesson.A_Sec01_TitleLesson
        state.Objectives = lesson.Objectives
        state.B_Sec02_Par = lesson.B_Sec02_Par
        state.C_Sec03_Quest01 = lesson.C_Sec03_Quest01
        state.D_Sec04_ParImgL = lesson.D_Sec04_ParImgL
        state.E_Sec05_Quest02 = lesson.E_Sec05_Quest02
        state.F_Sec06_ParImgR = lesson.F_Sec06_ParImgR
        state.G_Sec07_Par = lesson.G_Sec07_Par
        state.H_Sec08_Quest03 = lesson.H_Sec08_Quest03
        state.I_Sec09_Par = lesson.I_Sec09_Par
        state.J_Sec10_Par = lesson.J_Sec10_Par
        state.K_Sec11_ImgBig = lesson.K_Sec11_ImgBig
        state.L_Sec12_Quest04 = lesson.L_Sec12_Quest04
        state.M_Sec13_Par = lesson.M_Sec13_Par
        state.N_Sec14_ParImgL = lesson.N_Sec14_ParImgL
        state.O_Sec15_Par = lesson.O_Sec15_Par
        state.P_Sec16_Quest05 = lesson.P_Sec16_Quest05 
        state.Q_Sec17_VideoLink = lesson.Q_Sec17_VideoLink 
      },
      RESET_LESSONDATA (state, lesson){
        state.lesId = ""
        state._id = ""
        state.A_Sec01_TitleLesson = "Sorry, de gekozen les werd niet gevonden."
        state.Objectives = []
        state.B_Sec02_Par = ""
        state.C_Sec03_Quest01 = ""
        state.D_Sec04_ParImgL = ""
        state.E_Sec05_Quest02 = ""
        state.F_Sec06_ParImgR = ""
        state.G_Sec07_Par = ""
        state.H_Sec08_Quest03 = ""
        state.I_Sec09_Par = ""
        state.J_Sec10_Par = ""
        state.K_Sec11_ImgBig = ""
        state.L_Sec12_Quest04 = ""
        state.M_Sec13_Par = ""
        state.N_Sec14_ParImgL = ""
        state.O_Sec15_Par = ""
        state.P_Sec16_Quest05 = ""
        state.Q_Sec17_VideoLink = ""
      }
    },
    actions: {
      async getLesson( { commit }, lesson){
        //let response = await axios.get('/api/v1/lessons?A_Sec01_TitleLesson=' + lesson)
        let response = await axios.get('/api/v1/lessons/' + lesson)
        // SOMETHING WENT WRONG
        .catch( (error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log("response.data", error.response.data)
            console.log("response.status", error.response.status)
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
          }
            // show message: "Sorry, de gekozen les werd niet  gevonden."
            try {
              commit('SET_LESSONDATA', response.data.data)
            } catch (error) {
              commit('RESET_LESSONDATA', null)
            }   
        })
        // NOTHING WENT WRONG
        try {
          commit('SET_LESSONDATA', response.data.data)
        } catch (error) {
          console.log('Error: De les werd niet gevonden')
          commit('RESET_LESSONDATA', null)
        }   
     }
    }
  }
  