import axios from 'axios'


export default{
  namespaced: true,

  state: {
    // lesson: null
  },
  getters: {

  },
  mutations: {
  //   SET_LESSON(state, lesson){
  //     state.lesson = lesson
  //   }
  // },
  // actions: {
  //   async getLesson( {commit }, lesson){
  //      let response = await axios.get('/api/v1/lessons?A_Sec01_TitleLesson=' + lesson)
  //        console.log(response.data)
  //        commit('SET_LESSON', response.data.data)
  //   }
  
  }

}
