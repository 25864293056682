<template>
        <section class="P_Sec16_Quest05">
        <div class="container">
            <div class="row">
                <div>
                    <div class="col m6 s12">
                    <div  class="C col s12 m8 ">
                        <div class="card padding">
                            <div class="row valign-wrapper">
                                <div class="col s12">
                                <p class="right tag">P</p>
                                <h6 class="teal-text"><i>Opdracht:</i></h6>
                                <ul >
                                    <li>vraag 01</li>
                                    <li>vraag 02</li>
                                    <li>vraag 03</li>
                                    <li>vraag 04</li>
                                    <li>vraag 05</li>
                                    <li>vraag 06</li>
                                </ul>
                                <div class="row">
                                    <form class="col s12">
                                        <div class="row">
                                            <div class="input-field col s12" >
                                            <textarea id="textarea1" class="materialize-textarea" ></textarea>
                                            <label for="">(Noteer voor elk antwoord het nummer van de vraag)</label>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="col m6 s12">
                    <div class="input-field col s12 " :value='P_Sec16_Quest05_Question01' @input='$emit("update:P_Sec16_Quest05_Question01", $event.target.value)'>
                        <textarea id="textarea_PA" class="materialize-textarea "></textarea>
                        <label for="textarea_PA">Question 1</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='P_Sec16_Quest05_Question02' @input='$emit("update:P_Sec16_Quest05_Question02", $event.target.value)'>
                        <textarea id="textarea_PB" class="materialize-textarea "></textarea>
                        <label for="textarea_PB">Question 2</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='P_Sec16_Quest05_Question03' @input='$emit("update:P_Sec16_Quest05_Question03", $event.target.value)'>
                        <textarea id="textarea_PC" class="materialize-textarea "></textarea>
                        <label for="textarea_PC">Question 3</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='P_Sec16_Quest05_Question04' @input='$emit("update:P_Sec16_Quest05_Question04", $event.target.value)'>
                        <textarea id="textarea_PD" class="materialize-textarea"></textarea>
                        <label for="textarea_PD">Question 4</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='P_Sec16_Quest05_Question05' @input='$emit("update:P_Sec16_Quest05_Question05", $event.target.value)'>
                        <textarea id="textarea_PE" class="materialize-textarea"></textarea>
                        <label for="textarea_PE">Question 5</label>
                    </div>
                    <div class="input-field col s12 inputFields" :value='P_Sec16_Quest05_Question06' @input='$emit("update:P_Sec16_Quest05_Question06", $event.target.value)'>
                        <textarea id="textarea_PF" class="materialize-textarea"></textarea>
                        <label for="textarea_PF">Question 6</label>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    prop: {
        P_Sec16_Quest05_Question01: String,
        P_Sec16_Quest05_Question02: String,
        P_Sec16_Quest05_Question03: String,
        P_Sec16_Quest05_Question04: String,
        P_Sec16_Quest05_Question05: String,
        P_Sec16_Quest05_Question06: String
    },
    data(){
        return {
            P_Sec16_Quest05_Question01: "",
            P_Sec16_Quest05_Question02: "",
            P_Sec16_Quest05_Question03: "",
            P_Sec16_Quest05_Question04: "",
            P_Sec16_Quest05_Question05: "",
            P_Sec16_Quest05_Question06: ""
        }
    }
}
</script>

<style scoped>
.inputFields{
    margin-top: -10px;
}
</style>